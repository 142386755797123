/*import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)*/

const JwtService=require("../common/jwt.service");
const axios =require('axios');
import { createStore } from 'vuex';

const state = {
    niveauclickmenu:0,
    myscreenSize:0,
    classdefautmenu:"",
    isAuthenticated:false,
    beartoken:'',
    lancerchargement:false,
    paramsystem:[],
    paramsapp:[],
    userdata:[],
    isRole:[],
    allccessapp:[],
    appuserpicture:"",
    appusername:"",
    approle:"",
    appsociete:"",

   /* baseurl:process.env.MIX_APP_URL,*/
    baseurlapi:process.env.VUE_APP_API_URL,
}


export default createStore({
    strict:true,
    state,
    getters:{



        isAuthenticated:function (state) {
            return state.isAuthenticated || false
        },

        beartoken:function (state) {
            return state.beartoken || ''
        },

        lancerchargement:function (state) {
            return state.lancerchargement || false
        },


        appusername:function (state) {
            return state.appusername || ""
        },

        approle:function (state) {
            return state.approle || ""
        },

        appuserpicture:function (state) {
            return state.appuserpicture || ""
        },




    },
    mutations:{


        appsociete:function(state,{name}){

            state.appsociete=name;
        },

        lancerchargement:function(state,{value}){

            state.lancerchargement=value;
        },

        isAuthenticated:function(state,{value}){

            state.isAuthenticated=value;
        },

        beartoken:function(state,{value}){

            state.beartoken=value;
        },
        appusername:function(state,{name}){

            state.appusername=name;
        },
        approle:function(state,{name}){

            state.approle=name;
        },
        appuserpicture:function(state,{name}){

            state.appuserpicture=name;
        },


    },
    actions:{

      /*  paramsystem:function(context,value){

            context.commit('paramsystem',{value:value});
        },

        paramsapp:function(context,value){

            context.commit('paramsapp',{value:value});
        },

        userdata:function(context,value){

            context.commit('userdata',{value:value});
        },

        allccessapp:function(context,value){

            context.commit('allccessapp',{value:value});
        },




        classdefautmenu:function(context,value){

            context.commit('classdefautmenu',{value:value});
        },

        niveauclickmenu:function(context,value){

            context.commit('niveauclickmenu',{value:value});
        },*/

        isAuthenticated:function(context,value){

            context.commit('isAuthenticated',{value:value});
        },

        beartoken:function(context,value){

            context.commit('beartoken',{value:value});
        },

        appuserpicture:function(context,value){

            context.commit('appuserpicture',{value:value});
        },

        appusername:function(context,value){

            context.commit('appusername',{value:value});
        },

        approle:function(context,value){

            context.commit('approle',{value:value});
        },

        lancerchargement:function(context,value){

            context.commit('lancerchargement',{value:value});
        },


      /*  chektoken: async function (context,{idtoken,expirein=''}) {*/
        chektoken: async function (context,{idtoken}) {
           // context.commit('lancerchargement',{value:true});
            return new Promise( (resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource

                let url=context.state.baseurlapi+"/rfesh8495848484dfbfer498/"+idtoken+"/rpporpo498485161r8er1f5fporer"

                   axios
                    .get(url, {
                        headers: {
                           /* 'xccsrftoken':"tokenid",
                            'incsrftoken':"12458779955",
                            'btkcsrftoken':"rerueiriuerierieuiueri",*/
                        }
                    })
                    .then(response => {
                    if(response.data.refresh){

                        context.commit('isAuthenticated',{value:true})
                        context.commit('appusername',{name:JwtService.getTokenUser()})
                        context.commit('appuserpicture',{name:response.data.datas._picture})
                        context.commit('appsociete',{name:response.data.datas.mkey})
                        /*
                                                context.commit('userdata',{value:response.data.userData.userinfo})
                                                context.commit('beartoken',{value:response.data.userData.sitetoken})
                        */

                      /*  context.commit('isAuthenticated',{value:true});
                        context.commit('appusername',{name:JwtService.getTokenUser()})
                        context.commit('approle',{name:JwtService.getTokenRole()})
                        context.commit('appsociete',{name:JwtService.getTokenSociete()})
                        context.commit('listeallcaisses',{listeallcaisses:response.data.allcaisse})
                        //context.commit('allccessapp',{allccessapp:response.data.iujhi7855})
                        context.commit('accescomponent',{accescomponent:response.data.iujhi7855})
                        context.commit('beartoken',{value:response.data.beartoken})


                        context.commit('authorizechangementprix',{authorizechangementprix:response.data.__auth.authorizechangementprix})
                        context.commit('authorizenormalisation',{authorizenormalisation:response.data.__auth.authorizenormalisation})
                        context.commit('authorizeprix0',{authorizeprix0:response.data.__auth.authorizeprix0})
                        context.commit('authorizefacturactionpartiel',{authorizefacturactionpartiel:response.data.__auth.authorizefacturactionpartiel})
                        context.commit('authorizepointdevente',{authorizepointdevente:response.data.__auth.authorizepointdevente})
                        context.commit('authorizelaproceduredevente',{authorizelaproceduredevente:response.data.__auth.authorizelaproceduredevente})
                        context.commit('authorizefacturepourgenereranalyse',{authorizefacturepourgenereranalyse:response.data.__auth.authorizefacturepourgenereranalyse})
                        context.commit('authorizelaproceduredevalidationdocstock',{authorizelaproceduredevalidationdocstock:response.data.__auth.authorizelaproceduredevalidationdocstock})



                        context.commit('lignefacturation1',{lignefacturation1:response.data.__etatinfo.lignefacturation1})
                        context.commit('lignefacturation2',{lignefacturation2:response.data.__etatinfo.lignefacturation2})
                        context.commit('lignefacturation3',{lignefacturation3:response.data.__etatinfo.lignefacturation3})
                        context.commit('lignefacturationfooter1',{lignefacturationfooter1:response.data.__etatinfo.lignefacturationfooter1})
                        context.commit('lignefacturationfooter2',{lignefacturationfooter2:response.data.__etatinfo.lignefacturationfooter2})
                        context.commit('etatlogo',{etatlogo:response.data.__etatinfo.etatlogo})
                        context.commit('typfenetreventeaucomptoire',{typfenetreventeaucomptoire:response.data.__etatinfo.typfenetreventeaucomptoire})
                        context.commit('etatraisonsocial',{etatraisonsocial:response.data.__etatinfo.etatraisonsocial})
                        context.commit('etatifu',{etatifu:response.data.__etatinfo.etatifu})
                        context.commit('etatrccm',{etatrccm:response.data.__etatinfo.etatrccm})
                        context.commit('etattelephone',{etattelephone:response.data.__etatinfo.etattelephone})
                        context.commit('etatadresse',{etatadresse:response.data.__etatinfo.etatadresse})



                        context.commit('paramsystem',{value:response.data.userData.__sssys})
                        context.commit('paramsapp',{value:response.data.userData.__sssysapp})




                        JwtService.setAuthorization(JwtService.getToken())*/

                        //this.$router.push({name: 'acceuilpage'});


                    }
                    else{
                        JwtService.unsetTokenRole();
                        JwtService.unsetToken();
                       JwtService.unsetTokenEXPIRESIN();
                        JwtService.unsetTokenRole();
                        JwtService.unsetTokenID();
                        JwtService.unsetTokenUser();
                    }

                  return   resolve(response);
                }, error => {
                    // http failed, let the calling function know that action did not work out
                        return  reject(error);
                })
            })


        },





    },
});

