<template>

    <templatesite>

        <template v-slot:body>


        <main id="site__main" class="2xl:ml-[--w-side]  xl:ml-[--w-side-sm] p-2.5 h-[calc(100vh-var(--m-top))] mt-[--m-top]">

            <!-- timeline -->
            <div class="lg:flex 2xl:gap-16 gap-12 max-w-[1065px] mx-auto"  id="js-oversized">

                <div class="max-w-[680px] mx-auto">


                    <statutstorie></statutstorie>


                    <publicationpost></publicationpost>


                </div>

                <!-- sidebar -->
                <sidebardroite></sidebardroite>

            </div>

        </main>





        </template>






    </templatesite>



</template>

<script>

    import templatesite from "@/components/views/templatesite";
    import statutstorie from "@/components/componenttemplate/statutstorie";
    import publicationpost from "@/components/componenttemplate/publicationpost";
    import sidebardroite from "@/components/componenttemplate/sidebardroite";
    import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "index",
        components: {
            templatesite,
            statutstorie,
            publicationpost,
            sidebardroite,
        },

        data() {
            return {
                items:{
                    telephone:'',
                    password:'',
                    remember:true
                },
                allchurch:[],
                datamessageerror:[],
                messagesuccess:"",
            };
        },
        methods: {



            async  seconnecter(){
                this.datamessageerror=[];
                this.messagesuccess="";



                if (this.items.telephone===''){

                    this.datamessageerror.push("Veuillez saisir votre numéro de téléphone valide.")

                }

                if (this.items.password===''){

                    this.datamessageerror.push("Veuillez saisir votre mot de passe.")

                }

                if (this.items.password.length<6){

                    this.datamessageerror.push("Le mot de passe doit être de 6 caractères minimum")

                }


                if ( this.datamessageerror.length>0){

                    return
                }



                const t = this;

                let response =   axios  .post(process.env.VUE_APP_API_URL+'/login1478pojhgfderffg458755/'+allfunction.randomString(),this.items)
                let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.messagesuccess=res.data.message;



                            // console.log("res.data.datas._actk  ",res.data.datas._actk)

                            JwtService.setAuthorization(res.data.datas._actk);
                            //console.log("response.data.userData.allcaisse ",response.data.userData.allcaisse)
                            // t.$store.commit('beartoken',response.data.userData.token)

                            t.$store.commit('isAuthenticated',{value:true});
                            t.$store.commit('appusername',{name:res.data.datas._nme});
                            t.$store.commit('approle',{name:res.data.datas._rl});
                            // t.$store.commit('beartoken',{value:res.data.userData.beartoken});

                            console.log("res.data.datas._nme  ",res.data.datas._nme)
                            console.log("res.data.datas._rl  ",res.data.datas._rl)
                            JwtService.setToken(res.data.datas._actk);
                            JwtService.setTokenUser(res.data.datas._nme);
                            JwtService.setTokenRole(res.data.datas._rl);
                            // JwtService.setTokenID(response.data.datas.refresh_token);
                            //  JwtService.setTokenEXPIRESIN(response.data.userData.expires_in);



                            /* console.log("res.data.datas._nme  ",res.data.datas._nme)
                             console.log("res.data.datas._rl  ",res.data.datas._rl)*/

                            t.$router.push({name: 'acceuilpage'});




                        }else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async loaddata(){

                const t = this;

                //let response =   axios  .get(process.env.VUE_APP_API_URL+'/egl745874dsfd5771714dfe/'+allfunction.randomString())
                let response =   axios  .get(process.env.VUE_APP_API_URL+'/')
                let res= await response
                    .then(function (res){

                        console.log("res  ********* ",res)

                       /* if(res.data.res){
                            t.allchurch=res.data.m;
                        }else{
                            t.allchurch=[];
                        }

                        */


                    })
                    .catch((error)=>{
                            this.allchurch=[];


                        }
                    )
                    .finally(() =>{




                    })



            }

        },
        mounted() {

           // this.loaddata();
        }






    }
</script>

<style scoped>

</style>