<template>


    <div class="flex 2xl:gap-12 gap-10 mt-8 max-lg:flex-col" id="js-oversized">


        <div style="background: #fde047;width: 100%;height: 100px">
            <h2>pppppppppppconfiggroupe</h2>
        </div>



    </div>



</template>

<script>
    export default {
        /* eslint-disable */
        name: "configgroupe"
    }
</script>

<style scoped>

</style>