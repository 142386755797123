<template>
    <!-- placeholder -->
    <div class="rounded-xl shadow-sm p-4 space-y-4 bg-slate-200/40 animate-pulse border1 dark:bg-dark2">

        <div class="flex gap-3">
            <div class="w-9 h-9 rounded-full bg-slate-300/20"></div>
            <div class="flex-1 space-y-3">
                <div class="w-40 h-5 rounded-md bg-slate-300/20"></div>
                <div class="w-24 h-4 rounded-md bg-slate-300/20"></div>
            </div>
            <div class="w-6 h-6 rounded-full bg-slate-300/20"></div>
        </div>

        <div class="w-full h-52 rounded-lg bg-slate-300/10 my-3"> </div>

        <div class="flex gap-3">

            <div class="w-16 h-5 rounded-md bg-slate-300/20"></div>

            <div class="w-14 h-5 rounded-md bg-slate-300/20"></div>

            <div class="w-6 h-6 rounded-full bg-slate-300/20 ml-auto"></div>
            <div class="w-6 h-6 rounded-full bg-slate-300/20  "></div>
        </div>

    </div>
</template>

<script>
    export default {
        /* eslint-disable */
        name: "postloading"
    }
</script>

<style scoped>

</style>