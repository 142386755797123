<template>

    <div class="sm:flex">

        <div class="relative lg:w-[580px] md:w-96 w-full p-10 min-h-screen bg-white shadow-xl flex items-center pt-10 dark:bg-slate-900 z-10">

            <div class="w-full lg:max-w-sm mx-auto space-y-10" uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true">

                <!-- logo image-->
                <a href="#"> <img src="/assets/images/logo.png" class="w-28 absolute top-10 left-10 dark:hidden" alt=""></a>
                <a href="#"> <img src="/assets/images/logo-light.png" class="w-28 absolute top-10 left-10 hidden dark:!block" alt=""></a>

                <!-- logo icon optional -->
                <div class="hidden">
                    <img class="w-12" src="/assets/images/logo-icon.png" alt="Socialite html template">
                </div>

                <!-- title -->
                <div>
                    <h2 class="text-2xl font-semibold mb-1.5"> Inscrivez-vous pour commencer </h2>
                    <p class="text-sm text-gray-700 font-normal">Si vous avez déjà un compte,
                        <router-link to="/login" class="text-blue-700">Connectez-vous</router-link>
                    </p>
                </div>



                <div  class="space-y-7 text-sm text-black font-medium dark:text-white"
                      uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true">


                    <div v-if="datamessageerror.length>0" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">

                        <div>

                            <ul class="mt-1.5 list-disc list-inside">
                                <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                            </ul>
                        </div>
                    </div>


                    <div v-if="messagesuccess" class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                        <div class="flex">
                            <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                            <div>
                                <p class="font-bold">{{messagesuccess}}</p>

                            </div>
                        </div>
                    </div>




                    <div class="grid grid-cols-2 gap-4 gap-y-7">


                        <div class="col-span-2">
                            <label class="">Eglise local</label>
                            <div class="mt-2.5">
                                <select v-model="items.eglise" required class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200 dark:!border-slate-800 dark:!bg-white/5">
                                    <option v-for="(mdata,index) in allchurch" :key="index" :value="mdata.keyline">{{mdata.eglise}}</option>
                                </select>
                            </div>
                        </div>


                        <div class="col-span-2">
                            <label for="nomprenom" class="">Nom et prénom(s)</label>
                            <div class="mt-2.5">
                                <input v-model="items.nomprenom" id="nomprenom" name="text" type="text" placeholder="Nom et prénom(s)"
                                       required class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200
                                       dark:!border-slate-800 dark:!bg-white/5">
                            </div>
                        </div>


                        <div  class="col-span-2">
                            <label class="">Téléphone</label>
                            <div class="mt-2.5">
                                <input v-model="items.telephone"  type="number" placeholder="Téléphone" required="" class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200 dark:!border-slate-800 dark:!bg-white/5">
                            </div>
                        </div>




                        <div>
                            <label for="password" class="">Mot de passe</label>
                            <div class="mt-2.5">
                                <input id="password" v-model="items.password" type="password" placeholder="***"
                                       class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200 dark:!border-slate-800 dark:!bg-white/5">
                            </div>
                        </div>


                        <div>
                            <label for="cfpassword" class="">Confirmer mot de passe</label>
                            <div class="mt-2.5">
                                <input id="cfpassword" v-model="items.cfpassword" type="password" placeholder="***"  class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200 dark:!border-slate-800 dark:!bg-white/5">
                            </div>
                        </div>

                      <div class="col-span-2">
                            <button @click="sinscrire" class="button bg-primary text-white w-full">S'inscrire</button>
                        </div>

                    </div>



                </div>


            </div>

        </div>

        
        <!-- image slider -->
        <div class="flex-1 relative bg-primary max-md:hidden">


            <div class="relative w-full h-full" tabindex="-1" uk-slideshow="animation: slide; autoplay: true">

                <ul class="uk-slideshow-items w-full h-full">
                    <!--li class="w-full">
                        <img src="assets/images/post/img-3.jpg"  alt="" class="w-full h-full object-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                        <div class="absolute bottom-0 w-full uk-tr ansition-slide-bottom-small z-10">
                            <div class="max-w-xl w-full mx-auto pb-32 px-5 z-30 relative"  uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true" >
                                <img class="w-12" src="assets/images/logo-icon.png" alt="Socialite html template">
                                <h4 class="!text-white text-2xl font-semibold mt-7"  uk-slideshow-parallax="y: 600,0,0">  Connect With Friends </h4>
                                <p class="!text-white text-lg mt-7 leading-8"  uk-slideshow-parallax="y: 800,0,0;"> This phrase is more casual and playful. It suggests that you are keeping your friends updated on what’s happening in your life.</p>
                            </div>
                        </div>
                        <div class="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
                    </li-->
                    <li class="w-full">
                        <img src="assets/images/post/img-2.jpg"  alt="" class="w-full h-full object-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                        <div class="absolute bottom-0 w-full uk-tr ansition-slide-bottom-small z-10">
                            <div class="max-w-xl w-full mx-auto pb-32 px-5 z-30 relative"  uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true" >

                                <h4 class="!text-white text-2xl font-semibold mt-7"  uk-slideshow-parallax="y: 800,0,0">  Bienvenue sur la plateforme des ASSEMBLÉES DE DIEU de SURU LERE. </h4>
                            </div>
                        </div>
                        <div class="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
                    </li>
                </ul>

                <!-- slide nav -->
                <div class="flex justify-center">
                    <ul class="inline-flex flex-wrap justify-center  absolute bottom-8 gap-1.5 uk-dotnav uk-slideshow-nav"> </ul>
                </div>


            </div>


        </div>

    </div>


</template>

<script>
    const axios =require('axios');
import allfunction from '../../../allfunction'

    export default {
        /* eslint-disable */
        name: "register",
        data() {
            return {
                items:{
                    eglise:'',
                    nomprenom:'',
                    telephone:'',
                    password:'',
                    cfpassword:'',
                    remember:false
                },
                allchurch:[],
                datamessageerror:[],
                messagesuccess:"",
            };
        },
        components: {

        },
        methods: {

            seconnecter(){
                if (this.items.password==='' || this.items.login===''){

                    this.presentToast("Veuillez saisir votre login et votre mot de passe")

                    return
                }

                this.$store.commit('isAuthenticated',{value:true});

                this.$router.push({path: '/'});

            },
            async presentToast($message='') {
                const toast = await toastController.create({
                    /*message: 'Hello World!',
                    duration: 1500,
                    position: 'top',*/

                    message: $message,
                    color: 'danger',
                    duration: 1500,
                    position: 'top',
                    showCloseButton: true,
                    closeButtonText: 'Close'

                });

                await toast.present();
            },

            async  sinscrire(){
                this.datamessageerror=[];
                this.messagesuccess="";
                if (this.items.eglise===''){

                    this.datamessageerror.push("Veuillez sélectionner une église local valide.")

                }

                if (this.items.nomprenom===''){

                    this.datamessageerror.push("Veuillez saisir votre nom prénom(s)")

                }

                if (this.items.telephone===''){

                    this.datamessageerror.push("Veuillez saisir votre numéro de téléphone valide.")

                }

                if (this.items.password===''){

                    this.datamessageerror.push("Veuillez saisir votre mot de passe.")

                }

                if (this.items.password.length<6){

                    this.datamessageerror.push("Le mot de passe doit être de 6 caractères minimum")

                }

                if (this.items.password!==this.items.cfpassword){

                    this.datamessageerror.push("Veuillez saisir des mots de passe identique.")

                }


                if ( this.datamessageerror.length>0){

                    return
                }



                const t = this;

                let response =   axios  .post(process.env.VUE_APP_API_URL+'/inscription8948464489ezeeze/'+allfunction.randomString(),this.items)
                let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.items={
                                eglise:'',
                                    nomprenom:'',
                                    telephone:'',
                                    password:'',
                                    cfpassword:'',
                                    remember:false
                            }

                            t.messagesuccess=res.data.message;
                        }else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async loaddata(){

                /*
                if(this.typcltfrs==='vente'){

                    console.log("this.itemArticle  ************* ",this.itemArticle)

                    if (this.itemArticle.article===null || this.itemArticle.qtite===0 || this.itemArticle.qtite===0
                        || this.itemArticle.mtnt===0  || this.itemArticle.pu===0 || this.itemArticle.pu===0){

                        toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return ;
                    }

                }
                */


                const t = this;

                let response =   axios  .get(process.env.VUE_APP_API_URL+'/egl745874dsfd5771714dfe/'+allfunction.randomString())
                let res= await response
                    .then(function (res){

                        if(res.data.res){
                            t.allchurch=res.data.m;
                        }else{
                            t.allchurch=[];
                        }


                    })
                    .catch((error)=>{
                            this.allchurch=[];


                    }
                    )
                    .finally(() =>{




                    })



            }

        },
        mounted() {

            this.loaddata();
        }





    }
</script>

<style scoped>

</style>