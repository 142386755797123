import { createRouter, createWebHistory } from 'vue-router'
import index from "@/components/views/index";
import pagecompetence from "@/components/views/pages/competencespro";
import loginpage from "@/components/views/auth/login";
import profiluser from "@/components/views/user/profiluser";
import registerpage from "@/components/views/auth/register";

const routes = [
   {
        name:'login',
    path: '/login',
    component: loginpage,
        meta: {
            requiresAuth: false,
            roles: ['Membre','Administrateur']
        }
  },
   {
        name:'register',
    path: '/register',
    component: registerpage,
        meta: {
            requiresAuth: false,
            roles: ['Membre','Administrateur']
        }
  },

   {
       name:'acceuilpage',
    path: '/',
    component: index,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },


   {
       name:'profiluser',
    path: '/profil-utilisateur',
    component: profiluser,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },



   {
       name:'pagecompetence',
    path: '/competence-professionnelles',
    component: pagecompetence,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },






]

const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
   history: createWebHistory(),
  routes
})

export default router




