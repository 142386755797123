<template>


    <div class="sm:flex">

        <div class="relative lg:w-[580px] md:w-96 w-full p-10 min-h-screen bg-white shadow-xl flex items-center pt-10 dark:bg-slate-900 z-10">

            <div class="w-full lg:max-w-sm mx-auto space-y-10" uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true">

                <!-- logo image-->
                <a href="#"> <img src="/assets/images/icons/logo.jpg" class="w-28 absolute top-10 left-10 dark:hidden" alt=""></a>
                <a href="#"> <img src="/assets/images/icons/logo.jpg" class="w-28 absolute top-10 left-10 hidden dark:!block" alt=""></a>

                <!-- logo icon optional -->
                <div class="hidden">
                    <img class="w-12" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&amp;shade=600" alt="Socialite html template">
                </div>

                <!-- title -->
                <div>
                    <h2 class="text-2xl font-semibold mb-1.5"> Connectez-vous à votre compte </h2>
                    <p class="text-sm text-gray-700 font-normal">Si vous n'êtes pas encore inscrit.

                        <router-link to="/register" class="text-blue-700">S'inscrire</router-link>
                    </p>
                </div>

                <div v-if="datamessageerror.length>0" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">

                    <div>

                        <ul class="mt-1.5 list-disc list-inside">
                            <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                        </ul>
                    </div>
                </div>


                <div v-if="messagesuccess" class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                    <div class="flex">
                        <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                        <div>
                            <p class="font-bold">{{messagesuccess}}</p>

                        </div>
                    </div>
                </div>

                <!-- form -->
                <div class="space-y-7 text-sm text-black font-medium dark:text-white"
                      uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true">

                    <div  class="col-span-2">
                        <label class="">Téléphone</label>
                        <div class="mt-2.5">
                            <input v-model="items.telephone"  type="number" placeholder="Téléphone" required="" class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200 dark:!border-slate-800 dark:!bg-white/5">
                        </div>
                    </div>

                    <div>
                        <label for="password" class="">Mot de passe</label>
                        <div class="mt-2.5">
                            <input id="password" type="password" placeholder="***"
                                v-model="items.password"   class="!w-full !rounded-lg !bg-transparent !shadow-sm !border-slate-200 dark:!border-slate-800 dark:!bg-white/5">
                        </div>
                    </div>

                    <div class="flex items-center justify-between">

                        <div class="flex items-center gap-2.5">
                            <input v-model="items.remember" id="rememberme" name="rememberme" type="checkbox">
                            <label for="rememberme" class="font-normal">Se souvenir</label>
                        </div>

                        <router-link to="#" class="text-blue-700">Mot de passe oublier.</router-link>
                    </div>

                    <!-- submit button -->
                    <div>
                        <button @click="seconnecter"  class="button bg-primary text-white w-full">Se connecter</button>
                    </div>



                </div>


            </div>

        </div>

        <!-- image slider -->
        <div class="flex-1 relative bg-primary max-md:hidden">


            <div class="relative w-full h-full" tabindex="-1" uk-slideshow="animation: slide; autoplay: true">

                <ul class="uk-slideshow-items w-full h-full">
                    <!--li class="w-full">
                        <img src="assets/images/post/img-3.jpg"  alt="" class="w-full h-full object-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                        <div class="absolute bottom-0 w-full uk-tr ansition-slide-bottom-small z-10">
                            <div class="max-w-xl w-full mx-auto pb-32 px-5 z-30 relative"  uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true" >
                                <img class="w-12" src="assets/images/logo-icon.png" alt="Socialite html template">
                                <h4 class="!text-white text-2xl font-semibold mt-7"  uk-slideshow-parallax="y: 600,0,0">  Connect With Friends </h4>
                                <p class="!text-white text-lg mt-7 leading-8"  uk-slideshow-parallax="y: 800,0,0;"> This phrase is more casual and playful. It suggests that you are keeping your friends updated on what’s happening in your life.</p>
                            </div>
                        </div>
                        <div class="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
                    </li-->
                    <li class="w-full">
                        <img src="assets/images/post/img-2.jpg"  alt="" class="w-full h-full object-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                        <div class="absolute bottom-0 w-full uk-tr ansition-slide-bottom-small z-10">
                            <div class="max-w-xl w-full mx-auto pb-32 px-5 z-30 relative"  uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100 ;repeat: true" >

                                <h4 class="!text-white text-2xl font-semibold mt-7"  uk-slideshow-parallax="y: 800,0,0">  Bienvenue sur la plateforme des ASSEMBLÉES DE DIEU de SURU LERE. </h4>
                                 </div>
                        </div>
                        <div class="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
                    </li>
                </ul>

                <!-- slide nav -->
                <div class="flex justify-center">
                    <ul class="inline-flex flex-wrap justify-center  absolute bottom-8 gap-1.5 uk-dotnav uk-slideshow-nav"> </ul>
                </div>


            </div>


        </div>

    </div>




</template>

<script>
    import allfunction from "@/allfunction";
    import JwtService from "@/common/jwt.service";
    //import {setAuthorization} from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "login",
        components: {

        },
        data() {
            return {
                items:{
                    telephone:'',
                    password:'',
                    remember:true
                },
                allchurch:[],
                datamessageerror:[],
                messagesuccess:"",
            };
        },
        methods: {

            /*seconnecter(){
                if (this.items.password==='' || this.items.login===''){

                    this.presentToast("Veuillez saisir votre login et votre mot de passe")

                    return
                }

                this.$store.commit('isAuthenticated',{value:true});

                this.$router.push({path: '/'});

            },*/

            async  seconnecter(){
                this.datamessageerror=[];
                this.messagesuccess="";



                if (this.items.telephone===''){

                    this.datamessageerror.push("Veuillez saisir votre numéro de téléphone valide.")

                }

                if (this.items.password===''){

                    this.datamessageerror.push("Veuillez saisir votre mot de passe.")

                }

                if (this.items.password.length<6){

                    this.datamessageerror.push("Le mot de passe doit être de 6 caractères minimum")

                }


                if ( this.datamessageerror.length>0){

                    return
                }



                const t = this;

                let response =   axios  .post(process.env.VUE_APP_API_URL+'/login1478pojhgfderffg458755/'+allfunction.randomString(),this.items)
                let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.messagesuccess=res.data.message;



                           // console.log("res.data.datas._actk  ",res.data.datas._actk)

                            JwtService.setAuthorization(res.data.datas._actk);
                            //console.log("response.data.userData.allcaisse ",response.data.userData.allcaisse)
                            // t.$store.commit('beartoken',response.data.userData.token)

                            t.$store.commit('isAuthenticated',{value:true});
                            t.$store.commit('appusername',{name:res.data.datas._nme});
                            t.$store.commit('approle',{name:res.data.datas._rl});
                            t.$store.commit('appuserpicture',{name:res.data.datas._picture});
                            t.$store.commit('appsociete',{name:res.data.datas.mkey})

                           // t.$store.commit('beartoken',{value:res.data.userData.beartoken});


                            JwtService.setToken(res.data.datas._actk);
                            JwtService.setTokenUser(res.data.datas._nme);
                            JwtService.setTokenRole(res.data.datas._rl);
                           // JwtService.setTokenID(response.data.datas.refresh_token);
                          //  JwtService.setTokenEXPIRESIN(response.data.userData.expires_in);



                           /* console.log("res.data.datas._nme  ",res.data.datas._nme)
                            console.log("res.data.datas._rl  ",res.data.datas._rl)*/

                            t.$router.push({name: 'acceuilpage'});




                        }else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async loaddata(){

                /*
                if(this.typcltfrs==='vente'){

                    console.log("this.itemArticle  ************* ",this.itemArticle)

                    if (this.itemArticle.article===null || this.itemArticle.qtite===0 || this.itemArticle.qtite===0
                        || this.itemArticle.mtnt===0  || this.itemArticle.pu===0 || this.itemArticle.pu===0){

                        toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return ;
                    }

                }
                */


                const t = this;

                let response =   axios  .get(process.env.VUE_APP_API_URL+'/egl745874dsfd5771714dfe/'+allfunction.randomString())
                let res= await response
                    .then(function (res){

                        if(res.data.res){
                            t.allchurch=res.data.m;
                        }else{
                            t.allchurch=[];
                        }


                    })
                    .catch((error)=>{
                            this.allchurch=[];


                        }
                    )
                    .finally(() =>{




                    })



            }

        },
        mounted() {

          //  this.loaddata();
        }


    }
</script>

<style scoped>

</style>