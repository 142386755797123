<template>

    <templatesite>


        <template v-slot:body>

        <main id="site__main" class="2xl:ml-[--w-side]  xl:ml-[--w-side-sm] p-2.5 h-[calc(100vh-var(--m-top))] mt-[--m-top]">

            <div class="max-w-[1065px] mx-auto">

                <!-- Cover  -->
                <div class="bg-white shadow lg:rounded-b-2xl lg:-mt-10 dark:bg-dark2">

                    <!-- cover -->
                    <div class="relative overflow-hidden w-full lg:h-60 h-40">
                        <img src="assets/images/group/group-cover-4.jpg" alt="" class="h-full w-full object-cover inset-0"/>

                        <!-- overly -->
                        <div class="w-full bottom-0 absolute left-0 bg-gradient-to-t from -black/60 pt-10 z-10"></div>

                        <div class="absolute bottom-0 right-0 m-4 z-20">
                            <div class="flex items-center gap-3">
                                <button class="button bg-white/20 text-white flex items-center gap-2 backdrop-blur-small">Crop</button>
                                <button class="button bg-black/10 text-white flex items-center gap-2 backdrop-blur-small">Edit</button>
                            </div>
                        </div>

                    </div>

                    <div class="lg:px-10 md:p-5 p-3">

                        <div class="flex flex-col justify-center -mt-20">

                            <div class="relative h-20 w-20 mb-4 z-10">
                                <div class="relative overflow-hidden rounded-full md:border-[2px] border-gray-100 shrink-0 dark:border-slate-900 shadow">
                                    <img :src="$store.state.appuserpicture" alt="" class="h-full w-full object-cover inset-0"/>
                                </div>
                            </div>

                            <div class="flex lg:items-center justify-between max-md:flex-col max-md:gap-3">

                                <div class="flex-1">
                                    <h3 class="md:text-2xl text-lg font-bold text-black dark:text-white">{{$store.state.appusername}} </h3>
                                    <p  class=" font-normal text-gray-500 mt-2 flex gap-2 dark:text-white/80">
                                        <span> <b class="font-medium text-black dark:text-white">1.2K</b> likes </span>
                                        <span> • </span>
                                        <span> <b class="font-medium text-black dark:text-white">1.4K</b> followers </span>
                                    </p>
                                </div>

                                <div class="flex items-center gap-2 mt-1">
                                    <button class="button bg-primary text-white flex items-center gap-2  py-2 px-3.5 shadow max-lg:flex-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                            <path fill-rule="evenodd" d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902 1.168.188 2.352.327 3.55.414.28.02.521.18.642.413l1.713 3.293a.75.75 0 001.33 0l1.713-3.293a.783.783 0 01.642-.413 41.102 41.102 0 003.55-.414c1.437-.231 2.43-1.49 2.43-2.902V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zM6.75 6a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5zm0 2.5a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z" clip-rule="evenodd" />
                                        </svg>
                                        <span class="text-sm"> Chat  </span>
                                    </button>
                                    <button class="button bg-secondery flex items-center gap-2  py-2 px-3.5 max-lg:flex-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                            <path d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z" />
                                        </svg>
                                        <span class="text-sm"> Like  </span>
                                    </button>
                                    <div>
                                        <button type="button" class="rounded-lg bg-secondery flex px-2.5 py-2 dark:bg-dark2">
                                            <ion-icon name="ellipsis-horizontal" class="text-xl"/>
                                        </button>
                                        <!--div  class="w-[240px]" uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click;offset:10">
                                            <nav>
                                                <a href="#"> <ion-icon class="text-xl" name="pricetags-outline"></ion-icon> Unfollow </a>
                                                <a href="#"> <ion-icon class="text-xl" name="share-outline"></ion-icon> Share  </a>
                                                <a href="#"> <ion-icon class="text-xl" name="link-outline"></ion-icon>  Copy link </a>
                                                <a href="#"> <ion-icon class="text-xl" name="chatbubble-ellipses-outline"></ion-icon>  Sort comments </a>
                                                <a href="#"> <ion-icon class="text-xl" name="flag-outline"></ion-icon>  Report group</a>
                                                <hr>
                                                <a href="#" class="text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"> <ion-icon class="text-xl" name="stop-circle-outline"></ion-icon>  Block </a>
                                            </nav>
                                        </div-->
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>

                    <div class="flex items-center justify-between border-t border-gray-100 px-2 dark:border-slate-700">

                        <nav class="flex gap-0.5 rounded-xl overflow-hidden -mb-px text-gray-500 font-medium text-sm overflow-x-auto dark:text-white">
                            <!--a @click="optionmenu='publication'" href="#" :class="optionmenu==='publication'?actifclasspublication:defaultclasspublication">Publications</a-->
                            <a @click="optionmenu='profil'" href="#" :class="optionmenu==='profil'?actifclasspublication:defaultclasspublication">Profil</a>
                            <a @click="optionmenu='cv'" href="#" :class="optionmenu==='cv'?actifclasspublication:defaultclasspublication">Compétence (CV)</a>
                            <!--a @click="optionmenu='amie'" href="#" :class="optionmenu==='amie'?actifclasspublication:defaultclasspublication">Ami(e)s</a>
                            <a @click="optionmenu='photos'" href="#" :class="optionmenu==='photos'?actifclasspublication:defaultclasspublication">Photos</a>
                            <a @click="optionmenu='video'" href="#" :class="optionmenu==='video'?actifclasspublication:defaultclasspublication">Video</a>
                            <a @click="optionmenu='groupes'" href="#" :class="optionmenu==='groupes'?actifclasspublication:defaultclasspublication">Groupes</a-->
                        </nav>

                        <div class="flex items-center gap-1 text-sm p-3 bg-secondery py-2 mr-2 rounded-xl max-lg:hidden dark:bg-white/5">
                            <ion-icon name="search" class="text-lg"></ion-icon>
                            <input placeholder="Search .." class="!bg-transparent">
                        </div>

                    </div>


                </div>


                <publication v-show="optionmenu==='publication'"></publication>
                <configprofil v-show="optionmenu==='profil'"></configprofil>
                <configcv v-show="optionmenu==='cv'"></configcv>
                <configamie v-show="optionmenu==='amie'"></configamie>
                <configphoto v-show="optionmenu==='photos'"></configphoto>
                <configvideo v-show="optionmenu==='video'"></configvideo>
                <configgroupe v-show="optionmenu==='groupes'"></configgroupe>









            </div>

        </main>




        </template>

    </templatesite>


</template>

<script>

    import templatesite from "@/components/views/templatesite";
    import publication from "@/components/views/user/publication";
    import configprofil from "@/components/views/user/profil";
    import configcv from "@/components/views/user/configcv";
    import configamie from "@/components/views/user/configamie";
    import configphoto from "@/components/views/user/configphoto";
    import configvideo from "@/components/views/user/configvideo";
    import configgroupe from "@/components/views/user/configgroupe";
    import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "profiluser",
        components: {
            templatesite,
            configprofil,
            configcv,
            configamie,
            configphoto,
            configvideo,
            configgroupe,
            publication

        },

        data() {
            return {
                items:{
                    telephone:'',
                    password:'',
                    remember:true
                },
                allchurch:[],
                datamessageerror:[],
                messagesuccess:"",
                optionmenu:"profil",
                actifclasspublication:"inline-block py-3 leading-8 px-3.5 border-b-2 border-blue-600 text-blue-600",
                defaultclasspublication:"inline-block py-3 leading-8 px-3.5",
            };
        },
        methods: {



            async  seconnecter(){
                this.datamessageerror=[];
                this.messagesuccess="";



                if (this.items.telephone===''){

                    this.datamessageerror.push("Veuillez saisir votre numéro de téléphone valide.")

                }

                if (this.items.password===''){

                    this.datamessageerror.push("Veuillez saisir votre mot de passe.")

                }

                if (this.items.password.length<6){

                    this.datamessageerror.push("Le mot de passe doit être de 6 caractères minimum")

                }


                if ( this.datamessageerror.length>0){

                    return
                }



                const t = this;

                let response =   axios  .post(process.env.VUE_APP_API_URL+'/login1478pojhgfderffg458755/'+allfunction.randomString(),this.items)
                let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.messagesuccess=res.data.message;



                            // console.log("res.data.datas._actk  ",res.data.datas._actk)

                            JwtService.setAuthorization(res.data.datas._actk);
                            //console.log("response.data.userData.allcaisse ",response.data.userData.allcaisse)
                            // t.$store.commit('beartoken',response.data.userData.token)

                            t.$store.commit('isAuthenticated',{value:true});
                            t.$store.commit('appusername',{name:res.data.datas._nme});
                            t.$store.commit('approle',{name:res.data.datas._rl});
                            // t.$store.commit('beartoken',{value:res.data.userData.beartoken});

                            console.log("res.data.datas._nme  ",res.data.datas._nme)
                            console.log("res.data.datas._rl  ",res.data.datas._rl)
                            JwtService.setToken(res.data.datas._actk);
                            JwtService.setTokenUser(res.data.datas._nme);
                            JwtService.setTokenRole(res.data.datas._rl);
                            // JwtService.setTokenID(response.data.datas.refresh_token);
                            //  JwtService.setTokenEXPIRESIN(response.data.userData.expires_in);



                            /* console.log("res.data.datas._nme  ",res.data.datas._nme)
                             console.log("res.data.datas._rl  ",res.data.datas._rl)*/

                            t.$router.push({name: 'acceuilpage'});




                        }else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async loaddata(){

                const t = this;

                //let response =   axios  .get(process.env.VUE_APP_API_URL+'/egl745874dsfd5771714dfe/'+allfunction.randomString())
                let response =   axios  .get(process.env.VUE_APP_API_URL+'/')
                let res= await response
                    .then(function (res){

                        console.log("res  ********* ",res)

                        /* if(res.data.res){
                             t.allchurch=res.data.m;
                         }else{
                             t.allchurch=[];
                         }

                         */


                    })
                    .catch((error)=>{
                            this.allchurch=[];


                        }
                    )
                    .finally(() =>{




                    })



            }

        },
        mounted() {

            // this.loaddata();
        }






    }
</script>

<style scoped>

</style>