<template>
    <templatesite>
    <template v-slot:body>


        <main id="site__main" class="2xl:ml-[--w-side]  xl:ml-[--w-side-sm] p-2.5 h-[calc(100vh-var(--m-top))] mt-[--m-top]">

            <div class="page-heading">
                <h1 class="page-title"> Compétences professionnelles </h1>
            </div>

            <!-- timeline -->
            <div class="lg:flex 2xl:gap-16 gap-12 max-w-[1065px] mx-auto"  id="js-oversized">


                <div class="max-w-[680px] mx-auto">


                    <div class="md:max-w-[580px] mx-auto flex-1 xl:space-y-6 space-y-3">
                    <div class="grid md:grid-cols-1 md:gap-1 gap-4">

                        <template v-for="(cv,index) in allcv" :key="index">



                            <div class="flex md:items-center space-x-4 p-4 rounded-md box">
                                <div class="sm:w-20 w-14 sm:h-20 h-14 flex-shrink-0 rounded-lg relative">
                                    <img :src="cv.cvpicture" class="absolute w-full h-full inset-0 rounded-md object-cover shadow-sm" alt="">
                                </div>
                                <div class="flex-1">
                                    <router-link to="#" uk-toggle="target: #create-status" class="md:text-lg text-base font-semibold
                                    capitalize text-black dark:text-white"> {{cv.cvname}}   </router-link>
                                    <div class="flex space-x-2 items-center text-sm font-normal">
                                     <p style="text-align: justify">{{cv.lib1}}</p>
                                    </div>


                                </div>
                                <button uk-toggle="target: #create-status" type="button" class="button bg-primary-soft text-primary dark:text-white gap-1 max-md:hidden">
                                    <ion-icon name="eye" class="text-xl -ml-1 md hydrated" role="img" aria-label="eye"></ion-icon>
                                  Lire
                                   </button>

                            </div>

                        </template>


                    </div>

                    <div class="flex justify-center my-6 mb-5">
                        <button type="button" class="bg-white py-2 px-5 rounded-full shadow-md font-semibold text-sm dark:bg-dark2">Voir plus...</button>
                    </div>

                </div>




                </div>

                <!-- sidebar -->
                <sidebardroite></sidebardroite>

            </div>

        </main>



        <!-- create status -->
        <div class="hidden lg:p-20 uk- open" id="create-status" uk-modal="">

            <div class="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[1025px] w-full dark:bg-dark2">

                <div class="text-center py-4 border-b mb-0 dark:border-slate-700">
                    <h2 class="text-sm font-medium text-black"> CV :  </h2>

                    <!-- close button -->
                    <button type="button" class="button-icon absolute top-0 right-0 m-2.5 uk-modal-close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                </div>

                <!--div class="space-y-5 mt-3 p-2">
                    <textarea disabled class="w-full !text-black placeholder:!text-black !bg-white !border-transparent focus:!border-transparent
                     focus:!ring-transparent !font-normal !text-xl   dark:!text-white dark:placeholder:!text-white dark:!bg-slate-800"
                              name="" id=""  rows="6" placeholder="What do you have in mind?"></textarea>
                </div-->


                <div class="p-5 flex justify-between items-center">

                    <div class="flex items-center gap-2">
                        <button type="button" class="button bg-red-600 text-white py-2 px-12 text-[14px] uk-modal-close"> Fermer</button>
                    </div>
                </div>

            </div>

        </div>








    </template>
    </templatesite>
</template>

<script>
    import templatesite from "@/components/views/templatesite";
    import statutstorie from "@/components/componenttemplate/statutstorie";
    import publicationpost from "@/components/componenttemplate/publicationpost";
    import sidebardroite from "@/components/componenttemplate/sidebardroite";
    import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "competencespro",
        components: {
            templatesite,
            statutstorie,
            publicationpost,
            sidebardroite,
        },

        data() {
            return {
                items:{
                    telephone:'',
                    password:'',
                    remember:true
                },
                allcv:[],
                datamessageerror:[],
                messagesuccess:"",
            };
        },
        methods: {


            async loaddata(){

                const t = this;

                let url=process.env.VUE_APP_API_URL+'/egl7458returncvcompetence771714dfe/'+allfunction.randomString()

                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
              //  let response =   axios  .get(process.env.VUE_APP_API_URL+'/')
                let res= await response
                    .then(function (res){

                        console.log("res  ********* ",res)

                         if(res.data.success){
                             t.allcv=res.data.m;
                         }else{
                             t.allcv=[];
                         }


                        console.log("t.allcv  ********* ",t.allcv)

                    })
                    .catch((error)=>{
                            this.allcv=[];


                        }
                    )
                    .finally(() =>{




                    })



            }

        },
        mounted() {

             this.loaddata();
        }

    }
</script>

<style scoped>

</style>