<template>
    <div class="mb-8">

        <h3 class="font-extrabold text-2xl  text-black dark:text-white hidden"> Stories</h3>

        <div class="relative" tabindex="-1" uk-slider="auto play: true;finite: true" uk-lightbox="">

            <div class="py-5 uk-slider-container">

                <ul class="uk-slider-items w-[calc(100%+14px)]" uk-scrollspy="target: > li; cls: uk-animation-scale-up; delay: 20;repeat:true">
                    <li class="md:pr-3" uk-scrollspy-class="uk-animation-fade">
                        <div class="md:w-16 md:h-16 w-12 h-12 rounded-full relative border-2 border-dashed grid place-items-center bg-slate-200 border-slate-300 dark:border-slate-700 dark:bg-dark2 shrink-0"
                             uk-toggle="target: #create-story">
                            <ion-icon name="camera" class="text-2xl"></ion-icon>
                        </div>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-1.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-2.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-2.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-3.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-4.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-5.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-5.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-6.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-1.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-7.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-1.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-2.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-2.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-3.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-4.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-5.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-5.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-6.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2 hover:scale-[1.15] hover:-rotate-2 duration-300">
                        <a href="/assets/images/avatars/avatar-lg-1.jpg" data-caption="Caption 1">
                            <div class="md:w-16 md:h-16 w-12 h-12 relative md:border-4 border-2 shadow border-white rounded-full overflow-hidden dark:border-slate-700">
                                <img src="/assets/images/avatars/avatar-7.jpg" alt="" class="absolute w-full h-full object-cover">
                            </div>
                        </a>
                    </li>
                    <li class="md:pr-3 pr-2">
                        <div class="md:w-16 md:h-16 w-12 h-12 bg-slate-200/60 rounded-full dark:bg-dark2 animate-pulse"></div>
                    </li>
                </ul>

            </div>

            <div class="max-md:hidden">

                <button type="button" class="absolute -translate-y-1/2 bg-white shadow rounded-full top-1/2 -left-3.5 grid w-8 h-8 place-items-center dark:bg-dark3" uk-slider-item="previous"> <ion-icon name="chevron-back" class="text-2xl"></ion-icon></button>
                <button type="button" class="absolute -right-2 -translate-y-1/2 bg-white shadow rounded-full top-1/2 grid w-8 h-8 place-items-center dark:bg-dark3" uk-slider-item="next"> <ion-icon name="chevron-forward" class="text-2xl"></ion-icon> </button>

            </div>


        </div>

    </div>


    <!-- create story -->
    <div class="hidden lg:p-20" id="create-story" uk-modal="">

        <div class="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white p-7 shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2">

            <div class="text-center py-3 border-b -m-7 mb-0 dark:border-slate-700">
                <h2 class="text-sm font-medium"> Create Status ---</h2>

                <!-- close button -->
                <button type="button" class="button__ico absolute top-0 right-0 m-2.5 uk-modal-close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

            </div>

            <div class="space-y-5 mt-7">

                <div>
                    <label for="" class="text-base">What do you have in mind?---++++++ </label>
                    <input type="text"  class="w-full mt-3">
                </div>

                <div>
                    <div class="w-full h-72 relative border1 rounded-lg overflow-hidden bg-[url('../images/ad_pattern.png')] bg-repeat">

                        <label for="createStatusUrl" class="flex flex-col justify-center items-center absolute -translate-x-1/2 left-1/2 bottom-0 z-10 w-full pb-6 pt-10 cursor-pointer bg-gradient-to-t from-gray-700/60">
                            <input id="createStatusUrl" type="file" class="hidden" />
                            <ion-icon name="image" class="text-3xl text-teal-600"></ion-icon>
                            <span class="text-white mt-2">Browse to Upload image </span>
                        </label>

                        <img id="createStatusImage" src="#" alt="Uploaded Image" accept="image/png, image/jpeg" style="display:none;" class="w-full h-full absolute object-cover">

                    </div>

                </div>

                <div class="flex justify-between items-center">

                    <div class="flex items-start gap-2">
                        <ion-icon name="time-outline" class="text-3xl text-sky-600  rounded-full bg-blue-50 dark:bg-transparent"></ion-icon>
                        <p class="text-sm text-gray-500 font-medium"> Your Status will be available <br> for <span class="text-gray-800"> 24 Hours</span> </p>
                    </div>

                    <button type="button" class="button bg-blue-500 text-white px-8"> Create</button>

                </div>

            </div>

        </div>

    </div>



</template>

<script>
    export default {
        /* eslint-disable */
        name: "statutstorie"
    }
</script>

<style scoped>

</style>