

const axiosheaderdefault=()=>{

    return {
        'X-Requested-With': 'XMLHttpRequest',
        /* 'X-CSRF-TOKEN':document.querySelector('meta[name="csrf-token"]').getAttribute('content'),*/
        'Accept':'application/json',
        'Content-Type':'application/json',
        /*'Accept-Encoding':'gzip',*/
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',

    };

};



const randomString = (length=60) => {


    var chars = "0123456789abcdefghijklmnopqrstuvwxyz0123456789ABCDEFG755lkjjhjHIJKLMNOPQRSTUVWXYZ1234567890";

    var output = "";
    var x = 0;
    var i = 0;
    for(x = 0; x < length; x++) {
        i = Math.floor(Math.random() * 62);
        output += chars.charAt(i);
    }



    return output;
};


 const getYear = () => {
   // let now = moment();
    var ladate=new Date()
    let arraydate=[];
    let str = 0;
    const startappYear = 1955;
    const currentYear = ladate.getFullYear();
    const goBackYears = currentYear-startappYear;

    for (let i = 0; i <= goBackYears; i++) {
        str = currentYear - i;

        arraydate.push(str)
    }

    return arraydate;

};
const returnMonthbynumber=val =>{
    var answer = "";




// Only change code below this line
    switch (val) {

        case "01": answer=("Janvier");
            break;
        case "02": answer=("Février");
            break;
        case "03": answer=("Mars");
            break;
        case "04": answer=("Avril");
            break;
        case "05": answer=("Mai");
            break;
        case "06": answer=("Juin");
            break;
        case "07": answer=("Juillet");
            break;
        case "08": answer=("Août");
            break;
        case "09": answer=("Septembre");
            break;
        case "10": answer=("Octobre");
            break;
        case "11": answer=("Novembre");
            break;
        case "12": answer=("Décembre");
            break;}

// Only change code above this line
    return answer;
}



module.exports = {

    randomString,
    getYear,
    axiosheaderdefault,
    returnMonthbynumber,

}

