<template>

    <div class="2xl:gap-12 gap-10 mt-8 max-lg:flex-col" style=" background: white;border-radius: 5%;">

        <div id="setting_tab" class="uk-switcher md:py-12 md:px-20 p-6 overflow-hidden text-black text-sm" style="touch-action: pan-y pinch-zoom;">




            <div class="uk-active" style="">

                <div>
                    <div style="margin-bottom: 10px" v-if="datamessageerror.length>0" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">

                        <div>

                            <ul class="mt-1.5 list-disc list-inside">
                                <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                            </ul>
                        </div>
                    </div>


                    <div style="margin-bottom: 10px" v-if="messagesuccess" class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                        <div class="flex">
                            <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                            <div>
                                <p class="font-bold">{{messagesuccess}}</p>

                            </div>
                        </div>
                    </div>



                    <div class="space-y-6">

                        <div class="md:flex items-center gap-10">
                            <label class="md:w-32 text-right"> Nom et prénom(s) </label>
                            <div class="flex-1 max-md:mt-4">
                                <input type="text" v-model="items.nomprenom" placeholder="Nom et prénom(s)" class="w-full">
                            </div>
                        </div>

                        <div class="md:flex items-center gap-10">
                            <label class="md:w-32 text-right"> Téléphone </label>
                            <div class="flex-1 max-md:mt-4">
                                <input disabled type="number" v-model="items.telephone" placeholder="Téléphone" class="w-full">
                            </div>
                        </div>

                        <!--div class="md:flex items-start gap-10">
                            <label class="md:w-32 text-right"> Biographie </label>
                            <div class="flex-1 max-md:mt-4">
                                <textarea  class="w-full" rows="5" placeholder="Biographie"></textarea>
                            </div>
                        </div-->

                        <div class="md:flex items-center gap-10">
                            <label class="md:w-32 text-right"> Sexe </label>
                            <div class="flex-1 max-md:mt-4">
                                <select v-model="items.sexe" class="!border-0 !rounded-md w-full">
                                    <option value="Masculin">Masculin</option>
                                    <option value="Féminin">Féminin</option>
                                </select>
                            </div>
                        </div>

                        <div class="md:flex items-center gap-10">
                            <label class="md:w-32 text-right"> Relation </label>
                            <div class="flex-1 max-md:mt-4">
                                <select v-model="items.relation" class="!border-0 !rounded-md lg:w-1/2 w-full">

                                    <option value="Célibataire">Célibataire</option>
                                    <option value="Dans une relation">Dans une relation</option>
                                    <option value="Marié(e)">Marié(e)</option>
                                    <option value="Engagé(e)">Engagé(e)</option>
                                </select>
                            </div>
                        </div>

                        <div class="md:flex items-start gap-10 " >
                            <label class="md:w-32 text-right"> Avatar </label>
                            <div class="flex-1 flex items-center gap-5 max-md:mt-4">
                                <img :src="items.avatar" alt="" class="w-10 h-10 rounded-full">
                                <label for="mfile" style="cursor: pointer" class="px-4 py-1 rounded-full bg-slate-100/60 border dark:bg-slate-700 dark:border-slate-600 dark:text-white"> Change</label>
                                <input id="mfile" type="file" hidden>
                            </div>
                        </div>

                        <hr class="border-gray-100 dark:border-gray-700">

                        <div class="md:flex items-start gap-10">
                            <label class="md:w-40 text-right"> Mot de passe actuel </label>
                            <div class="flex-1 max-md:mt-4">
                                <input type="password" placeholder="******" class="w-full">
                            </div>
                        </div>

                        <div class="md:flex items-start gap-10">
                            <label class="md:w-40 text-right"> Nouveau mot de passe </label>
                            <div class="flex-1 max-md:mt-4">
                                <input type="password" placeholder="******" class="w-full">
                            </div>
                        </div>

                        <div class="md:flex items-start gap-10">
                            <label class="md:w-40 text-right"> Confirmez le mot de passe </label>
                            <div class="flex-1 max-md:mt-4">
                                <input type="password" placeholder="******" class="w-full">
                            </div>
                        </div>






                    </div>

                    <div class="flex items-center gap-4 mt-16 lg:pl-[10.5rem]">
                        <!--button type="submit" class="button lg:px-6 bg-secondery max-md:flex-1"> Cancle</button-->
                        <button @click="updateinfo" class="button lg:px-10 bg-primary text-white max-md:flex-1"> Modifier <span class="ripple-overlay"></span></button>
                    </div>

                </div>

            </div>



        </div>



    </div>



</template>

<script>
    import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');
    export default {
        /* eslint-disable */
        name: "profil",
        data() {
            return {
                items:{
                    telephone:'',
                    nomprenom:'',
                    sexe:'',
                    relation:'',
                    avatar:'',

                },
                datamessageerror:[],
                messagesuccess:'',

            };
        },
        methods: {



            async  updateinfo(){
                this.datamessageerror=[];
                this.messagesuccess="";



                if (this.items.nomprenom===''){

                    this.datamessageerror.push("Veuillez saisir votre nom et prénom(s).")

                }


                if (this.items.sexe===''){

                    this.datamessageerror.push("Veuillez sélectionner votre sexe.")

                }

                if (this.items.relation===''){

                    this.datamessageerror.push("Veuillez sélectionner votre situation matrimoniale.")

                }

                if ( this.datamessageerror.length>0){

                    return
                }


                    let url=process.env.VUE_APP_API_URL+'/egl745874uprofiluser771714dfe/'+this.$store.state.appsociete+'/'+allfunction.randomString();
                const t = this;
                let response =   axios  .post(url,this.items,  {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

               let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.messagesuccess=res.data.message;


                            t.$store.commit('appusername',{name:t.items.nomprenom});
                            JwtService.unsetTokenUser();
                            JwtService.setTokenUser(t.items.nomprenom);



                        }else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async loaddata(){

                const t = this;
               let url=""+process.env.VUE_APP_API_URL+'/egl745874profiluser771714dfe/'+this.$store.state.appsociete+'/'+allfunction.randomString();
                let response =   axios  .get(url,
                    {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                           'Accept':'application/json',
                            'Content-Type':'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                            'Access-Control-Allow-Headers': 'Content-Type',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        }
                    })

                let res= await response
                    .then(function (res){

                         if(res.data.success){
                             t.allchurch=res.data.m;


                             t.items.telephone=res.data.m._phone;
                             t.items.nomprenom=res.data.m._muser;
                             t.items.sexe=res.data.m._sex;
                             t.items.relation=res.data.m._matrimo;
                             t.items.avatar=res.data.m._avatar;


                         }else{
                             t.items={
                                 telephone:'',
                                     nomprenom:'',
                                     sexe:'',
                                     relation:'',
                                     avatar:'',

                             }
                         }




                    })
                    .catch((error)=>{
                           // this.allchurch=[];
                        this.items={
                            telephone:'',
                            nomprenom:'',
                            sexe:'',
                            relation:'',
                            avatar:'',

                        }

                        }
                    )
                    .finally(() =>{




                    })



            },


        },
        mounted() {

             this.loaddata();


        }

    }
</script>

<style scoped>

</style>