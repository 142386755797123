<template>

    <div class="2xl:gap-12 gap-10 mt-8 max-lg:flex-col" style=" background: white;border-radius: 1%;">

        <div id="setting_tab" class="uk-switcher md:py-12 md:px-20 p-6 overflow-hidden text-black text-sm" style="touch-action: pan-y pinch-zoom;">

            <div class="uk-active" style="">

                <div>

                    <div class="space-y-6">


                        <div role="alert" data-libele="qui-etes-vous">
                            <div class="bg-slate-600 text-white font-bold rounded-t px-4 py-2">

                                    <header class="bg-transparent space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
                                        <div class="flex items-center justify-between">
                                            <h2 style="color: white!important;" class="font-semibold text-white">Votre profil</h2>
                                            <!--button class="hover:bg-blue-400 group flex items-center rounded-md bg-blue-500 text-white text-sm font-medium pl-2 pr-3 py-2 shadow-sm">
                                                <svg width="20" height="20" fill="currentColor" class="mr-2" aria-hidden="true">
                                                    <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                                                </svg>
                                                Ajouter
                                            </button-->
                                        </div>

                                    </header>



                            </div>
                            <div style="background-color:transparent" class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">

                                <div class="md:flex items-start gap-10">
                                    <!--label class="md:w-32 text-right"> Bio </label-->
                                    <div class="flex-1 max-md:mt-4">
                                        <textarea v-model="itemsprofil.formation" class="w-full" maxlength="150" rows="10" placeholder="Votre profil"></textarea>
                                    </div>
                                </div>

                                <template v-if="typemessage==='profil'">


                                    <div style="margin-bottom: 10px" v-if="datamessageerror.length>0" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">

                                        <div>

                                            <ul class="mt-1.5 list-disc list-inside">
                                                <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                                            </ul>
                                        </div>
                                    </div>


                                    <div style="margin-bottom: 10px" v-if="messagesuccess" class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                                        <div class="flex">
                                            <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                            <div>
                                                <p class="font-bold">{{messagesuccess}}</p>

                                            </div>
                                        </div>
                                    </div>

                                </template>


                                <div class="flex items-center gap-4 mt-16 lg:pl-[10.5rem]">

                    <button @click="saveprofilcompetencecv('profil')" class="button lg:px-10 bg-primary text-white max-md:flex-1"> Modifier <span class="ripple-overlay"></span></button>
                </div>


                            </div>
                        </div>


                        <div role="alert" data-libele="formations">
                            <div class="bg-slate-600 text-white font-bold rounded-t px-4 py-2">

                                <header class="bg-transparent space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
                                    <div class="flex items-center justify-between">
                                        <h2 style="color: white!important;" class="font-semibold text-white">Formations</h2>
                                        <button @click="btnshowadd('formation')" class="hover:bg-blue-400 group flex items-center rounded-md bg-blue-500 text-white text-sm font-medium pl-2 pr-3 py-2 shadow-sm">
                                            <svg width="20" height="20" fill="currentColor" class="mr-2" aria-hidden="true">
                                                <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                                            </svg>
                                            Ajouter
                                        </button>
                                    </div>

                                </header>

                            </div>
                            <div style="background-color:transparent" class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">



                                <div v-if="showaddformation" style="margin-bottom: 20px" class="rounded-lg bg-white border border-gray-400 m-2">


                                    <div>

                                <div class="w-full sm:flex-equal-width px-2">
                                    <div class="py-2">
                                        <div class="flex items-center mb-1">
                                            <label for="focus-Formation" class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Formation</label>
                                        </div>
                                        <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                            <input v-model="itemsformation.formation" id="focus-Formation" data-s="Formation" type="text" size="1" class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent" placeholder="Formation">
                                        </div>
                                    </div>
                                </div>



                                <div class="grid md:grid-cols-2 md:gap-6">


                                    <div class="w-full sm:flex-equal-width px-2">
                                    <div class="py-2">
                                        <div class="flex items-center mb-1">
                                            <label for="focus-etablissement" class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Établissement</label>
                                        </div>
                                        <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                            <input v-model="itemsformation.etablissement" id="focus-etablissement" data-s="Formation" type="text" size="1" class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent" placeholder="Établissement">
                                        </div>
                                    </div>
                                </div>


                                <div class="w-full sm:flex-equal-width px-2">
                                    <div class="py-2">
                                        <div class="flex items-center mb-1">
                                            <label for="focus-ville" class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Pays / Ville</label>
                                        </div>
                                        <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                            <input v-model="itemsformation.paysville" id="focus-ville" data-s="Formation" type="text" size="1" class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent" placeholder="Pays / Ville">
                                        </div>
                                    </div>
                                </div>
                                </div>




                                <div class="grid md:grid-cols-2 md:gap-6">


                                    <div class="w-full sm:flex-equal-width px-2">
                                    <div class="py-2">
                                        <div class="flex items-center mb-1">
                                            <label for="focus-datedebut" class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Date de début</label>
                                        </div>
                                       <div class="grid md:grid-cols-2 md:gap-6">

                                                <div style="    margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                <select v-model="itemsformation.moisdebut" id="focus-datedebut" class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                    <option value="">Mois</option>
                                                    <option value="01">Janvier</option>
                                                    <option value="02">Février</option>
                                                    <option value="03">Mars</option>
                                                    <option value="04">Avril</option>
                                                    <option value="05">Mai</option>
                                                    <option value="06">Juin</option>
                                                    <option value="07">Juillet</option>
                                                    <option value="08">Août</option>
                                                    <option value="09">Septembre</option>
                                                    <option value="10">Octobre</option>
                                                    <option value="11">Novembre</option>
                                                    <option value="12">Décembre</option>
                                                </select>

                                                </div>

                                                 <div style="    margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">

                                                <select v-model="itemsformation.annedebut" id="focus-datedebut1" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                                rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                    <option value="">Année</option>
                                                    <option v-for="(year,index) in mgetyear" :key="index" :value="year">{{year}}</option>
                                                </select>





                                            </div>




                                        </div>
                                    </div>
                                </div>


                                <div class="w-full sm:flex-equal-width px-2">
                                    <div class="py-2">
                                        <div class="flex items-center mb-1">
                                            <label for="focus-datedefin" class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Date de fin</label>
                                        </div>


                                        <div class="grid md:grid-cols-2 md:gap-6">

                                            <div  style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                <select v-model="itemsformation.moisfin" id="focus-datedefin" class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                    <option value="">Mois</option>
                                                    <option value="01">Janvier</option>
                                                    <option value="02">Février</option>
                                                    <option value="03">Mars</option>
                                                    <option value="04">Avril</option>
                                                    <option value="05">Mai</option>
                                                    <option value="06">Juin</option>
                                                    <option value="07">Juillet</option>
                                                    <option value="08">Août</option>
                                                    <option value="09">Septembre</option>
                                                    <option value="10">Octobre</option>
                                                    <option value="11">Novembre</option>
                                                    <option value="12">Décembre</option>
                                                </select>

                                            </div>

                                            <div style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">

                                                <select v-model="itemsformation.annefin" id="focus-dateinf1" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                                rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                    <option value="">Année</option>
                                                    <option v-for="(year,index) in mgetyear" :key="index" :value="year">{{year}}</option>
                                                </select>





                                            </div>




                                        </div>
                                    </div>
                                </div>
                                </div>


                               <div class="md:flex items-start gap-10">
                                        <!--label class="md:w-32 text-right"> Bio </label-->
                                        <div class="flex-1 max-md:mt-4">
                                            <textarea v-model="itemsformation.description" class="w-full" rows="10" placeholder="Description"></textarea>
                                        </div>
                                    </div>




                                </div>

                                    <template v-if="typemessage==='formation'">


                                        <div style="margin-bottom: 10px" v-if="datamessageerror.length>0" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">

                                            <div>

                                                <ul class="mt-1.5 list-disc list-inside">
                                                    <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                                                </ul>
                                            </div>
                                        </div>


                                        <div style="margin-bottom: 10px" v-if="messagesuccess" class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                                            <div class="flex">
                                                <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                                <div>
                                                    <p class="font-bold">{{messagesuccess}}</p>

                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                    <div style="margin: 10px" class="flex items-center space-x-4">
                                        <button @click="savecompetencecv('formation')" style="background: blue;" class="text-white bg-green-100 hover:bg-gray-100 focus:ring-4
                                        focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                                        dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                            Enregistrer
                                        </button>
                                        <button @click="btndeleteshowadd('formation')" type="button" class="text-red-600 inline-flex items-center border border-red-600
                                        hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5
                                        text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                                            <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                            Supprimer
                                        </button>
                                    </div>

                                </div>




                                <template v-for="(data,index) in listedesformations" :key="index">


                                    <div style="margin-bottom: 20px" class="rounded-lg bg-white border border-gray-400 m-2">


                                        <div>

                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">
                                                    <div class="flex items-center mb-1">
                                                        <label   class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Formation</label>
                                                    </div>
                                                    <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                        <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.lib1}}</p>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="grid md:grid-cols-2 md:gap-6">


                                                <div class="w-full sm:flex-equal-width px-2">
                                                    <div class="py-2">
                                                        <div class="flex items-center mb-1">
                                                            <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Établissement</label>
                                                        </div>
                                                        <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                            <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.lib2}}</p>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="w-full sm:flex-equal-width px-2">
                                                    <div class="py-2">
                                                        <div class="flex items-center mb-1">
                                                            <label for="focus-ville" class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Pays / Ville</label>
                                                        </div>
                                                        <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                            <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.lib2}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                            <div class="grid md:grid-cols-2 md:gap-6">


                                                <div class="w-full sm:flex-equal-width px-2">
                                                    <div class="py-2">
                                                        <div class="flex items-center mb-1">
                                                            <label   class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Date de début</label>
                                                        </div>
                                                        <div class="grid md:grid-cols-2 md:gap-6">

                                                            <div style="    margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                                <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{returnmonth(data.mdebut)}}</p>

                                                            </div>

                                                            <div style="    margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">

                                                                <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.andebut}}</p>


                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="w-full sm:flex-equal-width px-2">
                                                    <div class="py-2">
                                                        <div class="flex items-center mb-1">
                                                            <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Date de fin</label>
                                                        </div>


                                                        <div class="grid md:grid-cols-2 md:gap-6">

                                                            <div  style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                                <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{returnmonth(data.mfin)}}</p>

                                                            </div>

                                                            <div style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                                <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.anfin}}</p>


                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="md:flex items-start gap-10">
                                                <!--label class="md:w-32 text-right"> Bio </label-->
                                                <div class="flex-1 max-md:mt-4">


                                                    <div style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                        <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.description}}</p>

                                                    </div>


                                                </div>
                                            </div>




                                        </div>


                                        <div style="margin: 10px" class="flex items-center space-x-4">
                                            <!--button @click="savecompetencecv('formation')" style="background: blue;" class="text-white bg-green-100 hover:bg-gray-100 focus:ring-4
                                        focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                                        dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                                Enregistrer
                                            </button-->
                                            <button @click="removeline(data,'formation')" type="button" class="text-red-600 inline-flex items-center border border-red-600
                                        hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5
                                        text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                                                <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                Supprimer
                                            </button>
                                        </div>

                                    </div>




                                </template>














                            </div>
                        </div>


                        <div role="alert" data-libelle="experience professionnelle">
                            <div class="bg-slate-600 text-white font-bold rounded-t px-4 py-2">
                                <header class="bg-transparent space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
                                    <div class="flex items-center justify-between">
                                        <h2 style="color: white!important;" class="font-semibold text-white">Expérience professionnelle</h2>
                                        <button @click="btnshowadd('experience')" class="hover:bg-blue-400 group flex items-center rounded-md bg-blue-500 text-white text-sm font-medium pl-2 pr-3 py-2 shadow-sm">
                                            <svg width="20" height="20" fill="currentColor" class="mr-2" aria-hidden="true">
                                                <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                                            </svg>
                                            Ajouter
                                        </button>
                                    </div>

                                </header>
                            </div>
                            <div style="background-color:transparent" class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3
                            text-red-700">




                                <div v-if="showaddexperienceprofessionnel" style="margin-bottom: 20px" class="rounded-lg bg-white border border-gray-400 m-2">


                                    <div>

                                        <div class="w-full sm:flex-equal-width px-2">
                                            <div class="py-2">
                                                <div class="flex items-center mb-1">
                                                    <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Poste</label>
                                                </div>
                                                <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                    <input v-model="itemsexperience.poste"  type="text" size="1" class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent" placeholder="Poste">
                                                </div>
                                            </div>
                                        </div>



                                        <div class="grid md:grid-cols-2 md:gap-6">


                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">
                                                    <div class="flex items-center mb-1">
                                                        <label class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Employeur</label>
                                                    </div>
                                                    <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                        <input v-model="itemsexperience.employeur" type="text" size="1" class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent" placeholder="Employeur">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">
                                                    <div class="flex items-center mb-1">
                                                        <label class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Pays / Ville</label>
                                                    </div>
                                                    <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                        <input v-model="itemsexperience.paysville" type="text" size="1" class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent" placeholder="Ville">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="grid md:grid-cols-2 md:gap-6">


                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">
                                                    <div class="flex items-center mb-1">
                                                        <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Date de début</label>
                                                    </div>
                                                    <div class="grid md:grid-cols-2 md:gap-6">

                                                        <div style="    margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                            <select v-model="itemsexperience.moisdebut"  class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                                <option value="">Mois</option>
                                                                <option value="01">Janvier</option>
                                                                <option value="02">Février</option>
                                                                <option value="03">Mars</option>
                                                                <option value="04">Avril</option>
                                                                <option value="05">Mai</option>
                                                                <option value="06">Juin</option>
                                                                <option value="07">Juillet</option>
                                                                <option value="08">Août</option>
                                                                <option value="09">Septembre</option>
                                                                <option value="10">Octobre</option>
                                                                <option value="11">Novembre</option>
                                                                <option value="12">Décembre</option>
                                                            </select>

                                                        </div>

                                                        <div style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                                        transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">

                                                            <select v-model="itemsexperience.annedebut" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                                rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                                <option value="">Année</option>
                                                                <option v-for="(year,index) in mgetyear" :key="index" :value="year">{{year}}</option>
                                                            </select>





                                                        </div>




                                                    </div>
                                                </div>
                                            </div>


                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">
                                                    <div class="flex items-center mb-1">
                                                        <label for="focus-datedefin" class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Date de fin</label>
                                                    </div>


                                                    <div class="grid md:grid-cols-2 md:gap-6">

                                                        <div  style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                            <select v-model="itemsexperience.moisfin"  class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                                <option value="">Mois</option>
                                                                <option value="01">Janvier</option>
                                                                <option value="02">Février</option>
                                                                <option value="03">Mars</option>
                                                                <option value="04">Avril</option>
                                                                <option value="05">Mai</option>
                                                                <option value="06">Juin</option>
                                                                <option value="07">Juillet</option>
                                                                <option value="08">Août</option>
                                                                <option value="09">Septembre</option>
                                                                <option value="10">Octobre</option>
                                                                <option value="11">Novembre</option>
                                                                <option value="12">Décembre</option>
                                                            </select>

                                                        </div>

                                                        <div style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">

                                                            <select v-model="itemsexperience.annefin" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                                rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                                <option value="">Année</option>
                                                                <option v-for="(year,index) in mgetyear" :key="index" :value="year">{{year}}</option>
                                                            </select>





                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="md:flex items-start gap-10">
                                            <!--label class="md:w-32 text-right"> Bio </label-->
                                            <div class="flex-1 max-md:mt-4">
                                                <textarea v-model="itemsexperience.description" class="w-full" rows="10" placeholder="Description"></textarea>
                                            </div>
                                        </div>




                                    </div>
                                    <template v-if="typemessage==='experience'">


                                        <div style="margin-bottom: 10px" v-if="datamessageerror.length>0" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">

                                            <div>

                                                <ul class="mt-1.5 list-disc list-inside">
                                                    <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                                                </ul>
                                            </div>
                                        </div>


                                        <div style="margin-bottom: 10px" v-if="messagesuccess" class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                                            <div class="flex">
                                                <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                                <div>
                                                    <p class="font-bold">{{messagesuccess}}</p>

                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                    <div style="margin: 10px" class="flex items-center space-x-4">
                                        <button @click="savecompetencecv('experience')" style="background: blue;" class="text-white bg-green-100 hover:bg-gray-100 focus:ring-4
                                        focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                                        dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                            Enregistrer
                                        </button>
                                        <button @click="btndeleteshowadd('experience')" type="button" class="text-red-600 inline-flex items-center border border-red-600
                                        hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5
                                        text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                                            <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                            Supprimer
                                        </button>
                                    </div>

                                </div>






                                <template v-for="(data,index) in listedesexperiences" :key="index">


                                    <div style="margin-bottom: 20px" class="rounded-lg bg-white border border-gray-400 m-2">


                                        <div>

                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">
                                                    <div class="flex items-center mb-1">
                                                        <label   class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Poste</label>
                                                    </div>
                                                    <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                        <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.lib1}}</p>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="grid md:grid-cols-2 md:gap-6">


                                                <div class="w-full sm:flex-equal-width px-2">
                                                    <div class="py-2">
                                                        <div class="flex items-center mb-1">
                                                            <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Employeur</label>
                                                        </div>
                                                        <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                            <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.lib2}}</p>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="w-full sm:flex-equal-width px-2">
                                                    <div class="py-2">
                                                        <div class="flex items-center mb-1">
                                                            <label for="focus-ville" class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Pays / Ville</label>
                                                        </div>
                                                        <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                            <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.lib2}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                            <div class="grid md:grid-cols-2 md:gap-6">


                                                <div class="w-full sm:flex-equal-width px-2">
                                                    <div class="py-2">
                                                        <div class="flex items-center mb-1">
                                                            <label   class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Date de début</label>
                                                        </div>
                                                        <div class="grid md:grid-cols-2 md:gap-6">

                                                            <div style="    margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                                <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{returnmonth(data.mdebut)}}</p>

                                                            </div>

                                                            <div style="    margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">

                                                                <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.andebut}}</p>


                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="w-full sm:flex-equal-width px-2">
                                                    <div class="py-2">
                                                        <div class="flex items-center mb-1">
                                                            <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Date de fin</label>
                                                        </div>


                                                        <div class="grid md:grid-cols-2 md:gap-6">

                                                            <div  style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                                <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{returnmonth(data.mfin)}}</p>

                                                            </div>

                                                            <div style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                                <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.anfin}}</p>


                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="md:flex items-start gap-10">
                                                <!--label class="md:w-32 text-right"> Bio </label-->
                                                <div class="flex-1 max-md:mt-4">


                                                    <div style="margin-bottom: 15px;" class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                        <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.description}}</p>

                                                    </div>


                                                </div>
                                            </div>




                                        </div>


                                        <div style="margin: 10px" class="flex items-center space-x-4">
                                            <!--button @click="savecompetencecv('formation')" style="background: blue;" class="text-white bg-green-100 hover:bg-gray-100 focus:ring-4
                                        focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                                        dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                                Enregistrer
                                            </button-->
                                            <button @click="removeline(data,'experience')"  type="button" class="text-red-600 inline-flex items-center border border-red-600
                                        hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5
                                        text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                                                <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                Supprimer
                                            </button>
                                        </div>

                                    </div>




                                </template>






                            </div>
                        </div>

                        <div role="alert" data-libelle="Compétences">
                            <div class="bg-slate-600 text-white font-bold rounded-t px-4 py-2">


                                <header class="bg-transparent space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
                                    <div class="flex items-center justify-between">
                                        <h2 style="color: white!important;" class="font-semibold text-white">Compétences</h2>
                                        <button @click="btnshowadd('competence')" class="hover:bg-blue-400 group flex items-center rounded-md bg-blue-500 text-white text-sm font-medium pl-2 pr-3 py-2 shadow-sm">
                                            <svg width="20" height="20" fill="currentColor" class="mr-2" aria-hidden="true">
                                                <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                                            </svg>
                                            Ajouter
                                        </button>
                                    </div>

                                </header>


                            </div>
                            <div style="background-color:transparent" class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">



                                <div v-if="showaddcompetence" style="margin-bottom: 20px" class="rounded-lg bg-white border border-gray-400 m-2">


                                    <div>

                                        <div class="w-full sm:flex-equal-width px-2">
                                            <div class="py-2">
                                                <div class="flex items-center mb-1">
                                                    <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Compétence </label>
                                                </div>
                                                <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                    <input  v-model="itemscompetence.competence" type="text" size="1" class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent" placeholder="Compétence">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w-full sm:flex-equal-width px-2">
                                            <div class="py-2">
                                                <div class="flex items-center mb-1">
                                                    <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Niveau </label>
                                                </div>
                                                <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                                            transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                    <select v-model="itemscompetence.niveau" id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                        <option value="">Choisissez</option>
                                                        <option value="Débutant(e)">Débutant(e)</option>
                                                        <option value="Bien">Bien</option>
                                                        <option value="Très bien">Très bien</option>
                                                        <option value="Excellent">Excellent</option>

                                                    </select>


                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <template v-if="typemessage==='competence'">


                                        <div style="margin-bottom: 10px" v-if="datamessageerror.length>0" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">

                                            <div>

                                                <ul class="mt-1.5 list-disc list-inside">
                                                    <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                                                </ul>
                                            </div>
                                        </div>


                                        <div style="margin-bottom: 10px" v-if="messagesuccess" class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                                            <div class="flex">
                                                <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                                <div>
                                                    <p class="font-bold">{{messagesuccess}}</p>

                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                    <div style="margin: 10px" class="flex items-center space-x-4">
                                        <button @click="savecompetencecv('competence')" style="background: blue;" class="text-white bg-green-100 hover:bg-gray-100 focus:ring-4
                                        focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                                        dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                            Enregistrer
                                        </button>
                                        <button @click="btndeleteshowadd('competence')" type="button" class="text-red-600 inline-flex items-center border border-red-600
                                        hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5
                                        text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                                            <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                            Supprimer
                                        </button>
                                    </div>

                                </div>


                                <template v-for="(data,index) in listedescompetences" :key="index">



                                    <div style="margin-bottom: 20px" class="rounded-lg bg-white border border-gray-400 m-2">


                                        <div>

                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">
                                                    <div class="flex items-center mb-1">
                                                        <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Compétence </label>
                                                    </div>
                                                    <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                        <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.lib1}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">
                                                    <div class="flex items-center mb-1">
                                                        <label  class="flex-grow truncate block
                                            font-medium text-gray-600 text-sm">Niveau </label>
                                                    </div>
                                                    <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                                            transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">


                                                        <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.niveau}}</p>



                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                           <div style="margin: 10px" class="flex items-center space-x-4">

                                            <button @click="removeline(data,'competence')" type="button" class="text-red-600 inline-flex items-center border border-red-600
                                        hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5
                                        text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                                                <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                Supprimer
                                            </button>
                                        </div>

                                    </div>




                                </template>





                            </div>
                        </div>

                        <div role="alert" data-libelle="Réalisations">
                            <div class="bg-slate-600 text-white font-bold rounded-t px-4 py-2">


                                <header class="bg-transparent space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
                                    <div class="flex items-center justify-between">
                                        <h2 style="color: white!important;" class="font-semibold text-white">Réalisations</h2>
                                        <button @click="btnshowadd('realisation')" class="hover:bg-blue-400 group flex items-center rounded-md bg-blue-500 text-white text-sm font-medium pl-2 pr-3 py-2 shadow-sm">
                                            <svg width="20" height="20" fill="currentColor" class="mr-2" aria-hidden="true">
                                                <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                                            </svg>
                                            Ajouter
                                        </button>
                                    </div>

                                </header>


                            </div>
                            <div style="background-color:transparent" class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">


                                <div v-if="showaddrealisation" style="margin-bottom: 20px" class="rounded-lg bg-white border border-gray-400 m-2">


                                    <div>

                                        <div class="w-full sm:flex-equal-width px-2">
                                            <div class="flex-1 max-md:mt-4">
                                                <textarea v-model="itemsrealisation.description" class="w-full" rows="10" placeholder="Description"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <template v-if="typemessage==='realisation'">


                                        <div style="margin-bottom: 10px" v-if="datamessageerror.length>0" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">

                                            <div>

                                                <ul class="mt-1.5 list-disc list-inside">
                                                    <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                                                </ul>
                                            </div>
                                        </div>


                                        <div style="margin-bottom: 10px" v-if="messagesuccess" class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                                            <div class="flex">
                                                <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                                <div>
                                                    <p class="font-bold">{{messagesuccess}}</p>

                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                    <div style="margin: 10px" class="flex items-center space-x-4">
                                        <button @click="savecompetencecv('realisation')" style="background: blue;" class="text-white bg-green-100 hover:bg-gray-100 focus:ring-4
                                        focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                                        dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                            Enregistrer
                                        </button>
                                        <button @click="btndeleteshowadd('realisation')" type="button" class="text-red-600 inline-flex items-center border border-red-600
                                        hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5
                                        text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                                            <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                            Supprimer
                                        </button>
                                    </div>

                                </div>



                                <template v-for="(data,index) in listedesrealisations" :key="index">



                                    <div style="margin-bottom: 20px" class="rounded-lg bg-white border border-gray-400 m-2">


                                        <div>

                                            <div class="w-full sm:flex-equal-width px-2">
                                                <div class="py-2">

                                                    <div class="relative flex flex-row  border focus-within:border-brand-400 focus-within:bg-brand-50 rounded
                                   transition-colors duration-150 text-gray-800 border-transparent bg-gray-100 ">
                                                        <p class="flex-1 appearance-none outline-none
                                       ltr py-2 ps-3 text-base pe-3  bg-transparent">{{data.description}}</p>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>

                                        <div style="margin: 10px" class="flex items-center space-x-4">

                                            <button @click="removeline(data,'realisation')" type="button" class="text-red-600 inline-flex items-center border border-red-600
                                        hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5
                                        text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                                                <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                Supprimer
                                            </button>
                                        </div>

                                    </div>




                                </template>





                            </div>
                        </div>

                        <!--div role="alert">
                            <div class="bg-slate-600 text-white font-bold rounded-t px-4 py-2">
                                Intérêts
                            </div>
                            <div style="background-color:transparent" class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                                <p>Something not ideal might be happening.</p>
                            </div>
                        </div>

                        <div role="alert">
                            <div class="bg-slate-600 text-white font-bold rounded-t px-4 py-2">
                                Langue
                            </div>
                            <div style="background-color:transparent" class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                                <p>Something not ideal might be happening.</p>
                            </div>
                        </div>

                        <div role="alert">
                            <div class="bg-slate-600 text-white font-bold rounded-t px-4 py-2">
                                Certificats
                            </div>
                            <div style="background-color:transparent" class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                                <p>Something not ideal might be happening.</p>
                            </div>
                        </div-->



                    </div>


                </div>

            </div>



        </div>



    </div>

</template>

<script>

    import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "configcv",
        data() {
            return {
                mgetyear:allfunction.getYear(),

                showaddformation:false,
                showaddexperienceprofessionnel:false,
                showaddcompetence:false,
                showaddrealisation:false,

                itemsprofil:{



                    description:'',
                    formation:'',
                    etablissement:'',
                    paysville:'',
                    moisdebut:'',
                    annedebut:'',
                    moisfin:'',
                    annefin:'',
                    poste:'',
                    employeur:'',
                    niveau:'',
                    competence:'',

                },

                itemsformation:{



                    description:'',
                    formation:'',
                    etablissement:'',
                    paysville:'',
                    moisdebut:'',
                    annedebut:'',
                    moisfin:'',
                    annefin:'',
                    poste:'',
                    employeur:'',
                    niveau:'',
                    competence:'',

                },

                itemsexperience:{


                    description:'',
                    formation:'',
                    etablissement:'',
                    paysville:'',
                    moisdebut:'',
                    annedebut:'',
                    moisfin:'',
                    annefin:'',
                    poste:'',
                    employeur:'',
                    niveau:'',
                    competence:'',


                },

                itemscompetence:{

                    description:'',
                    formation:'',
                    etablissement:'',
                    paysville:'',
                    moisdebut:'',
                    annedebut:'',
                    moisfin:'',
                    annefin:'',
                    poste:'',
                    employeur:'',
                    niveau:'',
                    competence:'',
               },

                itemsrealisation:{
                    description:'',
                    formation:'',
                    etablissement:'',
                    paysville:'',
                    moisdebut:'',
                    annedebut:'',
                    moisfin:'',
                    annefin:'',
                    poste:'',
                    employeur:'',
                    niveau:'',
                    competence:'',
               },

                listedesrealisations:[],
                listedescompetences:[],
                listedesexperiences:[],
                listedesformations:[],
                listedesprofils:[],
                datamessageerror:[],
                messagesuccess:'',
                typemessage:''

            };
        },
        methods: {

            returnmonth(val){
                return allfunction.returnMonthbynumber(val)
            },

                btnshowadd(typline){

                        let t=this

                    if (typline==="formation"){

                        this.showaddformation=true;

                    }



                    if (typline==="realisation"){
                        this.showaddrealisation=true;

                    }



                    if (typline==="competence"){
                        this.showaddcompetence=true;

                    }



                    if (typline==="experience"){
                        this.showaddexperienceprofessionnel=true;

                    }



                    t.itemsformation={
                        description:'',
                        formation:'',
                        etablissement:'',
                        paysville:'',
                        moisdebut:'',
                        annedebut:'',
                        moisfin:'',
                        annefin:'',
                        poste:'',
                        employeur:'',
                        niveau:'',
                        competence:'',


                    }
                    t.itemsexperience={
                        description:'',
                        formation:'',
                        etablissement:'',
                        paysville:'',
                        moisdebut:'',
                        annedebut:'',
                        moisfin:'',
                        annefin:'',
                        poste:'',
                        employeur:'',
                        niveau:'',
                        competence:'',


                    }

                    t.itemscompetence={
                        description:'',
                        formation:'',
                        etablissement:'',
                        paysville:'',
                        moisdebut:'',
                        annedebut:'',
                        moisfin:'',
                        annefin:'',
                        poste:'',
                        employeur:'',
                        niveau:'',
                        competence:'',
                    }

                    t.itemsrealisation={
                        description:'',
                        formation:'',
                        etablissement:'',
                        paysville:'',
                        moisdebut:'',
                        annedebut:'',
                        moisfin:'',
                        annefin:'',
                        poste:'',
                        employeur:'',
                        niveau:'',
                        competence:'',
                    }



                },


                btndeleteshowadd(typline){



                    let t=this

                    if (typline==="formation"){

                        this.showaddformation=false;

                    }



                    if (typline==="realisation"){
                        this.showaddrealisation=false;

                    }



                    if (typline==="competence"){
                        this.showaddcompetence=false;

                    }



                    if (typline==="experience"){
                        this.showaddexperienceprofessionnel=false;

                    }



                    t.itemsformation={
                        description:'',
                        formation:'',
                        etablissement:'',
                        paysville:'',
                        moisdebut:'',
                        annedebut:'',
                        moisfin:'',
                        annefin:'',
                        poste:'',
                        employeur:'',
                        niveau:'',
                        competence:'',


                    }
                    t.itemsexperience={
                        description:'',
                        formation:'',
                        etablissement:'',
                        paysville:'',
                        moisdebut:'',
                        annedebut:'',
                        moisfin:'',
                        annefin:'',
                        poste:'',
                        employeur:'',
                        niveau:'',
                        competence:'',


                    }

                    t.itemscompetence={
                        description:'',
                        formation:'',
                        etablissement:'',
                        paysville:'',
                        moisdebut:'',
                        annedebut:'',
                        moisfin:'',
                        annefin:'',
                        poste:'',
                        employeur:'',
                        niveau:'',
                        competence:'',
                    }

                    t.itemsrealisation={
                        description:'',
                        formation:'',
                        etablissement:'',
                        paysville:'',
                        moisdebut:'',
                        annedebut:'',
                        moisfin:'',
                        annefin:'',
                        poste:'',
                        employeur:'',
                        niveau:'',
                        competence:'',
                    }

                },



            async  updateinfo(){
                this.datamessageerror=[];
                this.messagesuccess="";



                if (this.items.nomprenom===''){

                    this.datamessageerror.push("Veuillez saisir votre nom et prénom(s).")

                }


                if (this.items.sexe===''){

                    this.datamessageerror.push("Veuillez sélectionner votre sexe.")

                }

                if (this.items.relation===''){

                    this.datamessageerror.push("Veuillez sélectionner votre situation matrimoniale.")

                }

                if ( this.datamessageerror.length>0){

                    return
                }



                const t = this;
                let url =process.env.VUE_APP_API_URL+'/egl745874uprofiluser771714dfe/'+this.$store.state.appsociete+'/'+allfunction.randomString()
                let response =   axios  .post(url,this.items,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.messagesuccess=res.data.message;


                            t.$store.commit('appusername',{name:t.items.nomprenom});
                            JwtService.unsetTokenUser();
                            JwtService.setTokenUser(t.items.nomprenom);



                        }else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async  savecompetencecv(typline){
                this.datamessageerror=[];
                this.messagesuccess="";

                //this.items.typline=typline;

               /* if (this.items.nomprenom===''){

                    this.datamessageerror.push("Veuillez saisir votre nom et prénom(s).")

                }


                if (this.items.sexe===''){

                    this.datamessageerror.push("Veuillez sélectionner votre sexe.")

                }

                if (this.items.relation===''){

                    this.datamessageerror.push("Veuillez sélectionner votre situation matrimoniale.")

                }

                if ( this.datamessageerror.length>0){

                    return
                }*/

                    let mitems={}


                if (typline==="formation"){


                    if (this.itemsformation.formation===""){
                        this.datamessageerror.push("Veuillez saisir la formation.")
                    }


                    if (this.itemsformation.etablissement===""){
                        this.datamessageerror.push("Veuillez saisir l'établissement.")
                    }


                    if (this.itemsformation.paysville===""){
                        this.datamessageerror.push("Veuillez saisir le pays/ville.")
                    }


                    if (this.itemsformation.moisdebut==="" || this.itemsformation.annedebut===""){
                        this.datamessageerror.push("Veuillez sélectionner les informations de la date début.")
                    }


                    if (this.itemsformation.moisfin==="" || this.itemsformation.annefin===""){
                        this.datamessageerror.push("Veuillez sélectionner les informations de la date fin.")
                    }


                    if (this.itemsformation.description===""){
                        this.datamessageerror.push("Veuillez saisir la description. Veuillez saisir Néant si vous n'avez pas de description.")
                    }



                    mitems=this.itemsformation


                }



                if (typline==="realisation"){



                    if (this.itemsrealisation.description===""){
                        this.datamessageerror.push("Veuillez saisir la description.")
                    }

                    mitems=this.itemsrealisation

                }



                if (typline==="competence"){


                    if (this.itemscompetence.competence===""){
                        this.datamessageerror.push("Veuillez saisir la compétence.")
                    }


                    if (this.itemscompetence.niveau===""){
                        this.datamessageerror.push("Veuillez saisir le niveau de la compétence.")
                    }


                    mitems=this.itemscompetence

                }



                if (typline==="experience"){

                    if (this.itemsexperience.poste===""){
                        this.datamessageerror.push("Veuillez saisir le poste.")
                    }


                    if (this.itemsexperience.employeur===""){
                        this.datamessageerror.push("Veuillez saisir l'employeur.")
                    }


                    if (this.itemsexperience.paysville===""){
                        this.datamessageerror.push("Veuillez saisir le pays/ville.")
                    }


                    if (this.itemsexperience.moisdebut==="" || this.itemsexperience.annedebut===""){
                        this.datamessageerror.push("Veuillez sélectionner les informations de la date début.")
                    }


                    if (this.itemsexperience.moisfin==="" || this.itemsexperience.annefin===""){
                        this.datamessageerror.push("Veuillez sélectionner les informations de la date fin.")
                    }


                    if (this.itemsexperience.description===""){
                        this.datamessageerror.push("Veuillez saisir la description. Veuillez saisir Néant si vous n'avez pas de description.")
                    }




                    mitems=this.itemsexperience

                }


                mitems.t=typline;

                this.typemessage=typline;


                if ( this.datamessageerror.length>0){

                    return
                }




                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl7458aaddcvcompetence771714dfe/'+typline+'/'+this.$store.state.appsociete+'/'+allfunction.randomString();
                let response =   axios  .post(url,mitems,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.messagesuccess=res.data.message;

                            t.itemsformation={
                                description:'',
                                formation:'',
                                etablissement:'',
                                paysville:'',
                                moisdebut:'',
                                annedebut:'',
                                moisfin:'',
                                annefin:'',
                                poste:'',
                                employeur:'',
                                niveau:'',
                                competence:'',


                            }
                            t.itemsexperience={
                                description:'',
                                formation:'',
                                etablissement:'',
                                paysville:'',
                                moisdebut:'',
                                annedebut:'',
                                moisfin:'',
                                annefin:'',
                                poste:'',
                                employeur:'',
                                niveau:'',
                                competence:'',


                            }

                            t.itemscompetence={
                                description:'',
                                formation:'',
                                etablissement:'',
                                paysville:'',
                                moisdebut:'',
                                annedebut:'',
                                moisfin:'',
                                annefin:'',
                                poste:'',
                                employeur:'',
                                niveau:'',
                                competence:'',
                            }

                            t.itemsrealisation={
                                description:'',
                                formation:'',
                                etablissement:'',
                                paysville:'',
                                moisdebut:'',
                                annedebut:'',
                                moisfin:'',
                                annefin:'',
                                poste:'',
                                employeur:'',
                                niveau:'',
                                competence:'',
                            }


                       t.loaddata(typline)


                        }
                        else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async  saveprofilcompetencecv(typline){
                this.datamessageerror=[];
                this.messagesuccess="";

                    let mitems={}


                    if (this.itemsprofil.formation===""){
                        this.datamessageerror.push("Veuillez saisir votre profil.")
                    }

                    mitems=this.itemsprofil

                mitems.t=typline;

                this.typemessage=typline;

                if ( this.datamessageerror.length>0){

                    return
                }


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl7458aaddprofilecvcompetence771714dfe/'+typline+'/'+this.$store.state.appsociete+'/'+allfunction.randomString();
                let response =   axios  .post(url,mitems,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.messagesuccess=res.data.message;

                                    t.loaddata(typline)


                        }
                        else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async  removeline(mitems,typline){
                this.datamessageerror=[];
                this.messagesuccess="";

                //this.items.typline=typline;

               /* if (this.items.nomprenom===''){

                    this.datamessageerror.push("Veuillez saisir votre nom et prénom(s).")

                }


                if (this.items.sexe===''){

                    this.datamessageerror.push("Veuillez sélectionner votre sexe.")

                }

                if (this.items.relation===''){

                    this.datamessageerror.push("Veuillez sélectionner votre situation matrimoniale.")

                }

                if ( this.datamessageerror.length>0){

                    return
                }*/

                 //   let mitems={}


              /*  if (typline==="formation"){


                    if (this.itemsformation.formation===""){
                        this.datamessageerror.push("Veuillez saisir la formation.")
                    }


                    if (this.itemsformation.etablissement===""){
                        this.datamessageerror.push("Veuillez saisir l'établissement.")
                    }


                    if (this.itemsformation.paysville===""){
                        this.datamessageerror.push("Veuillez saisir le pays/ville.")
                    }


                    if (this.itemsformation.moisdebut==="" || this.itemsformation.annedebut===""){
                        this.datamessageerror.push("Veuillez sélectionner les informations de la date début.")
                    }


                    if (this.itemsformation.moisfin==="" || this.itemsformation.annefin===""){
                        this.datamessageerror.push("Veuillez sélectionner les informations de la date fin.")
                    }


                    if (this.itemsformation.description===""){
                        this.datamessageerror.push("Veuillez saisir la description. Veuillez saisir Néant si vous n'avez pas de description.")
                    }



                    mitems=this.itemsformation


                }



                if (typline==="realisation"){



                    if (this.itemsrealisation.description===""){
                        this.datamessageerror.push("Veuillez saisir la description.")
                    }

                    mitems=this.itemsrealisation

                }



                if (typline==="competence"){


                    if (this.itemscompetence.competence===""){
                        this.datamessageerror.push("Veuillez saisir la compétence.")
                    }


                    if (this.itemscompetence.niveau===""){
                        this.datamessageerror.push("Veuillez saisir le niveau de la compétence.")
                    }


                    mitems=this.itemscompetence

                }



                if (typline==="experience"){

                    if (this.itemsexperience.poste===""){
                        this.datamessageerror.push("Veuillez saisir le poste.")
                    }


                    if (this.itemsexperience.employeur===""){
                        this.datamessageerror.push("Veuillez saisir l'employeur.")
                    }


                    if (this.itemsexperience.paysville===""){
                        this.datamessageerror.push("Veuillez saisir le pays/ville.")
                    }


                    if (this.itemsexperience.moisdebut==="" || this.itemsexperience.annedebut===""){
                        this.datamessageerror.push("Veuillez sélectionner les informations de la date début.")
                    }


                    if (this.itemsexperience.moisfin==="" || this.itemsexperience.annefin===""){
                        this.datamessageerror.push("Veuillez sélectionner les informations de la date fin.")
                    }


                    if (this.itemsexperience.description===""){
                        this.datamessageerror.push("Veuillez saisir la description. Veuillez saisir Néant si vous n'avez pas de description.")
                    }




                    mitems=this.itemsexperience

                }*/


                mitems.t=typline;

                this.typemessage=typline;


                if ( this.datamessageerror.length>0){

                    return
                }




                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl7458removcvcompetence771714dfe/'+typline+'/'+this.$store.state.appsociete+'/'+allfunction.randomString();
                let response =   axios  .post(url,mitems,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        if(res.data.success){

                            t.messagesuccess=res.data.message;

                            t.itemsformation={
                                description:'',
                                formation:'',
                                etablissement:'',
                                paysville:'',
                                moisdebut:'',
                                annedebut:'',
                                moisfin:'',
                                annefin:'',
                                poste:'',
                                employeur:'',
                                niveau:'',
                                competence:'',


                            }
                            t.itemsexperience={
                                description:'',
                                formation:'',
                                etablissement:'',
                                paysville:'',
                                moisdebut:'',
                                annedebut:'',
                                moisfin:'',
                                annefin:'',
                                poste:'',
                                employeur:'',
                                niveau:'',
                                competence:'',


                            }

                            t.itemscompetence={
                                description:'',
                                formation:'',
                                etablissement:'',
                                paysville:'',
                                moisdebut:'',
                                annedebut:'',
                                moisfin:'',
                                annefin:'',
                                poste:'',
                                employeur:'',
                                niveau:'',
                                competence:'',
                            }

                            t.itemsrealisation={
                                description:'',
                                formation:'',
                                etablissement:'',
                                paysville:'',
                                moisdebut:'',
                                annedebut:'',
                                moisfin:'',
                                annefin:'',
                                poste:'',
                                employeur:'',
                                niveau:'',
                                competence:'',
                            }


                       t.loaddata(typline)


                        }
                        else{
                            t.datamessageerror=res.data.datamessage;
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })




            },

            async loaddata(typline){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl7458cvcompetence771714dfe/'+typline+'/'+this.$store.state.appsociete+'/'+allfunction.randomString();

                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        if(res.data.success){



                       if (typline==="formation"){
                           t.listedesformations=[];
                           t.listedesformations=res.data.m;

                       }



                       if (typline==="realisation"){
                           t.listedesrealisations=[];
                           t.listedesrealisations=res.data.m;

                       }



                       if (typline==="competence"){
                           t.listedescompetences=[];
                           t.listedescompetences=res.data.m;

                       }



                       if (typline==="experience"){
                           t.listedesexperiences=[];
                           t.listedesexperiences=res.data.m;

                       }



                       if (typline==="profil"){
                          // t.listedesexperiences=[];
                          // t.listedesexperiences=res.data.m;


                           if (res.data.m.length>0){
                               t.itemsprofil.formation= res.data.m[0].lib1

                           }else{
                               t.itemsprofil.formation=""
                           }

                       }






                        }else{

                        }




                    })
                    .catch((error)=>{
                            // this.allchurch=[];


                        }
                    )
                    .finally(() =>{




                    })



            },


        },
        mounted() {

            this.loaddata('formation');
            this.loaddata('realisation');
            this.loaddata('competence');
            this.loaddata('experience');
            this.loaddata('profil');


        }
    }
</script>

<style scoped>

</style>